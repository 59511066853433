import { useDispatch, useSelector } from "react-redux";
import { Col, Row } from "reactstrap";
import { socket } from "../../../../services/services.sockets";
// import { socket } from "../../../../services/services.sockets";
import { getOrdersBySession } from "../../../../store/actions/orderAction";
import { serveStatusUpdateSession } from "../../../../store/actions/tableSessionsAction";
import KellnerCard from "./KellnerCard";
const ReadyToServe = ({
  activeSessions,
  setSelectedSession,
  setWarn,
  setAlertMsg,
}) => {
  const dispatch = useDispatch();
  const orderStore = useSelector((state) => state?.orders?.orders);

  // =========== BUMP and SERVE ==============
  const readyToBumpNServeFunc = (activeSession, notServe) => {
    if (notServe) {
      dispatch(
        serveStatusUpdateSession({
          sessionId: activeSession?._id,
          bumpStatus: false,
          serve_status: true,
          allOrders: orderStore,
        })
      ).then(() => {
        socket.emit("processing", {});
      });
    }
  };
  return (
    <Row>
      {activeSessions
        ?.filter((item) => item?.bump == true)
        ?.sort((a, b) => a.table_id - b.table_id)
        ?.map((activeSession, index) => {
          return (
            <Col sm="6" xs="6" md="2" lg="2" key={index}>
              <KellnerCard
                activeSession={activeSession}
                readyToBumpNServe={() => {
                  readyToBumpNServeFunc(activeSession, activeSession?.bump);
                }}
                setSelectedSession={() => {
                  setSelectedSession(activeSession);
                  dispatch(getOrdersBySession(activeSession));
                }}
                serve={activeSession.bump}
                setWarn={setWarn}
                setAlertMsg={setAlertMsg}
              />
            </Col>
          );
        })}
    </Row>
  );
};

export default ReadyToServe;
