import React, { useState, useEffect, useRef } from "react";
import OcardHeader from "./ocardHeader";
import "./ocard.css";
import OrderFooter from "./ofooter";
import { useSelector, useDispatch } from "react-redux/es/exports";
// import { cookingProcessUpdate } from "../../../store/actions/orderAction";
import {
  CLEAR_ID,
  ORDER_DISH_COOK,
  STEPS_MODAL_OPEN,
} from "../../../store/types";
import { Spinner } from "reactstrap";
import { SingleCardCookingFunc } from "../../../utils/cookingFunc";
import { convertToXml } from "../../PrintAssets/printxml";
// import { socket } from "../../../services/services.sockets";
// import { Spinner } from "reactstrap";
const Ocard = ({
  data,
  dish,
  activeStation,
  // setStationSelectModal,
  // setOrderObj,
  setUpdateOrder,
  SingleLoading,
  setListenDataChange,
}) => {
  const order = data.find((product) =>
    product.salesEntries.some((item) => item._id === dish?._id)
  );
  const sessions = useSelector((state) => state?.tableSession?.sessions);
  const dishStationChangeLoader = useSelector(
    (state) => state?.orders?.dishStationChangeLoader
  );
  const dishPeriorityLoading = useSelector(
    (state) => state?.orders?.dishPeriorityLoading
  );
  const removeItemLoading = useSelector(
    (state) => state?.orders?.removeItemLoading
  );
  const orderPriortyChangeLoader = useSelector(
    (state) => state?.orders?.orderPriortyChangeLoader
  );
  const cookingProcessLoader = useSelector(
    (state) => state?.orders?.cookingUpdateLoader
  );
  const selectedDish = useSelector((state) => state?.info.id.Id);
  const selectedOrder = useSelector((state) => state?.info.oId);
  const [dataLoading, setDataLoading] = useState(false);

  const ordrIDStore = useSelector((state) => state?.info?.id.stationName?._id);
  const dispatch = useDispatch();
  // useEffect(() => {
  //   setListenDataChange(dataLoading);
  // }, [dataLoading]);

  useEffect(() => {
    if (!dishPeriorityLoading) {
      setDataLoading(dishPeriorityLoading);
    } else if (dish._id === selectedDish) {
      setDataLoading(dishPeriorityLoading);
    }
  }, [dishPeriorityLoading, dish._id, selectedDish]);
  useEffect(() => {
    if (!orderPriortyChangeLoader) {
      setDataLoading(orderPriortyChangeLoader);
    } else if (dish._id === selectedOrder?.Id) {
      setDataLoading(orderPriortyChangeLoader);
    }
  }, [orderPriortyChangeLoader, dish._id, selectedDish?._id]);
  useEffect(() => {
    if (!dishStationChangeLoader) {
      setDataLoading(dishStationChangeLoader);
    } else if (dish._id === selectedDish) {
      setDataLoading(dishStationChangeLoader);
    }
  }, [dishStationChangeLoader, dish._id, selectedDish]);
  useEffect(() => {
    if (!removeItemLoading) {
      setDataLoading(removeItemLoading);
    } else if (dish._id === selectedDish) {
      setDataLoading(removeItemLoading);
    }
  }, [removeItemLoading, dish._id, selectedDish]);
  useEffect(() => {
    if (!cookingProcessLoader) {
      setDataLoading(cookingProcessLoader);
    }
  }, [cookingProcessLoader]);
  let componentRef = useRef();
  let printref = useRef();

  const SetOrderSelectForCook = (item, dish) => {
    dispatch({
      type: ORDER_DISH_COOK,
      payload: {
        orderid: item,
        saleEntry: dish,
      },
    });
  };
  //=========================Print Functions =============================//
  const handlePrint = () => {
    var content = componentRef.current;
    var pri = printref.current.contentWindow;
    pri.document.open();
    pri.document.write(content.innerHTML);
    pri.document.close();
    pri.focus();
    pri.print();
  };
  const handlePrintXML = (order) => {
    const dataConverted = convertToXml(order);
    printWithTMAssistant(
      "tmprintassistant://",
      "tmprintassistant.epson.com/",
      "print?",
      "success",
      "ver",
      "data-type",
      "data",
      dataConverted,
      "1",
      "eposprintxml",
      "yes",
      "reselect"
    );
  };
  function printWithTMAssistant(
    appscheme,
    host,
    action,
    query_success,
    query_ver,
    query_datatype,
    query_data,
    xmlData,
    ver,
    datatype,
    reselect,
    query_reselect
  ) {
    // create the URL string
    var urlData =
      appscheme +
      host +
      action +
      query_success +
      "=" +
      encodeURIComponent("") +
      "&" +
      query_ver +
      "=" +
      ver +
      "&" +
      query_datatype +
      "=" +
      datatype +
      "&" +
      query_reselect +
      "=" +
      reselect +
      "&" +
      query_data +
      "=" +
      encodeURIComponent(xmlData);
    window.location.replace(urlData);
  }
  return (
    <>
      <div className="o-card">
        <div className="content-card">
          <div
            onClick={() => dispatch({ type: CLEAR_ID })}
            className={ordrIDStore === dish?._id ? "o-card-overlay" : "abc"}
          ></div>
          <div className="d-block">
            {/* header start */}
            <OcardHeader
              dish={dish}
              order={order}
              tableNo={order?.tableNumber}
              // setSingleOrder={setSingleOrder}
              setUpdateOrder={(obj) => {
                setUpdateOrder(obj);
              }}
            />
            {/* header fin */}
          </div>
          {SingleLoading === true || dataLoading ? (
            <div className="d-flex justify-content-center align-items-center mt-5">
              <Spinner />
            </div>
          ) : (
            <>
              <div className="d-block">
                <div className="takeaway-sec d-flex justify-content-between">
                  <span>{order?.order_type?.toUpperCase()}</span>
                  <span onClick={SetOrderSelectForCook(order, dish)}>
                    {order?.sessionName !== null ? order?.sessionName : "12:45"}
                  </span>
                </div>
                <div
                  className={
                    dish?.priority === "urgent"
                      ? "item-sec bghotpink d-flex align-items-center justify-content-between pl-2"
                      : "item-sec d-flex align-items-center justify-content-between pl-2"
                  }
                >
                  <span
                    className="item-name-ocard f-14 px-1"
                    onClick={() => {
                      SingleCardCookingFunc(
                        order,
                        dish,
                        sessions,
                        setDataLoading,
                        setListenDataChange
                      );
                    }}
                  >
                    {dish?.itemName !== null
                      ? dish?.itemName?.toUpperCase()
                      : "KAMUT BASE"}
                  </span>
                  <span>{dish?.station?.toUpperCase()}</span>
                </div>
              </div>
              <div className="order-body">
                <div className="dishName d-block p-2">
                  {dish?.subLineItems.map((sl, index) => {
                    return (
                      <div key={index}>
                        {(sl?.type === "standard" ||
                          sl.type === "Standard" ||
                          sl?.type === "normal") &&
                        sl?.isSingleOnly === true ? (
                          <>
                            <div className="d-flex ">
                              <img
                                src="/img/icons/MIDARROWRIGHT_BLACK.png"
                                className="pt-1"
                                height={20}
                                alt="item-logo"
                              />

                              <span className="f-14 px-1">{sl?.itemName}</span>
                            </div>
                          </>
                        ) : (
                          <></>
                        )}
                      </div>
                    );
                  })}
                </div>

                {/* || dish?.greenItems?.length > 0 ? ( */}
                <div className="two-card-sec">
                  {dish?.redItems?.length > 0 ? (
                    <div className="block-card bg-pink py-3">
                      <span>
                        {" "}
                        <img
                          src="/img/icons/MINUS_BLACK.png"
                          alt="minus"
                          height={12}
                        />
                      </span>
                      {dish?.redItems?.map((item, index) => {
                        return (
                          <div className="card-porduct pl-2" key={index}>
                            <span className="d-block ">{item?.itemName} </span>
                          </div>
                        );
                      })}
                    </div>
                  ) : (
                    ""
                  )}
                  {dish?.greenItems?.length > 0 ? (
                    <div className=" block-card bg-lightGreen mt-2 py-3">
                      <span>
                        {" "}
                        <img
                          src="/img/icons/PLUS_BLACK.png"
                          alt="minus"
                          height={12}
                        />
                      </span>
                      {dish?.greenItems?.map((item, index) => {
                        return (
                          <div className="card-porduct pl-2" key={index}>
                            <span className="d-block ">{item?.itemName} </span>
                          </div>
                        );
                      })}
                    </div>
                  ) : (
                    ""
                  )}
                </div>

                {!dish?.steps ? (
                  ""
                ) : (
                  <div className="ingredients-sec my-2 ">
                    <div
                      className="bill-img"
                      onClick={() => {
                        dispatch({
                          type: STEPS_MODAL_OPEN,
                          payload: dish?.steps,
                        });
                      }}
                    >
                      <img
                        src="/img/icons/BILL_GRAY.png"
                        height={20}
                        alt="bill"
                      />
                    </div>
                    {dish?.ingredients?.map((ing, index) => {
                      return (
                        <div className="ing-name px-2" key={index}>
                          <span className=" d-block">
                            <span>{parseInt(ing?.quantity)} -</span> {ing?.name}
                          </span>
                        </div>
                      );
                    })}
                  </div>
                )}
              </div>
            </>
          )}

          <div className=" d-block align-items-end footer">
            <OrderFooter
              // setCookingModalObj={setCookingModalObj}
              // setCookingModalOpen={setCookingModalOpen}
              activeStation={activeStation}
              handlePrint={handlePrint}
              handlePrintXML={handlePrintXML}
              order={order}
              dish={dish}
              cooked={
                dish?.status === "cooked" && dish?.payment_status === "paid"
                  ? "bg_paid"
                  : dish?.status === "cooked" || dish?.status === "half_paid"
                  ? "bg-green"
                  : dish?.status === "cooking"
                  ? "bg-yellow"
                  : dish?.payment_status === "paid"
                  ? "bg-White"
                  : "bg-gray"
              }
              createdAt={dish?.createdAt}
              cookingStarted={
                order?.cooking_started ? order?.cooking_started : false
              }
              setListenDataChange={setListenDataChange}
              setDataLoading={setDataLoading}
              sessions={sessions}
            />
          </div>
        </div>
        <div className="d-flex table-sec invisible">
          <div
            style={{ width: "300px", display: "hidden", height: "fit-content" }}
            ref={componentRef}
          >
            <div style={{ width: "100%", textAlign: "center" }}>
              <h2>
                {order?.sessionName} {order?.tableNumber}
              </h2>
              <>
                <h3>{dish?.itemName}</h3>
                <div className="dishName d-block px-2">
                  {dish?.subLineItems.map((sl, index) => {
                    return (
                      <div key={index}>
                        {(sl?.type === "standard" ||
                          sl?.type === "Standard" ||
                          sl?.type === "normal") &&
                        sl?.isSingleOnly === true ? (
                          <>
                            <div className="d-flex ">
                              <img
                                src="/img/icons/MIDARROWRIGHT_BLACK.png"
                                className="pt-1"
                                height={20}
                                alt="item-logo"
                              />
                              <span className="f-14 px-1">{sl?.itemName}</span>
                            </div>
                          </>
                        ) : null}
                      </div>
                    );
                  })}
                </div>
                {dish?.redItems?.length > 0 || dish?.greenItems?.length > 0 ? (
                  <div
                    style={{
                      width: "100%",
                      display: "flex",
                      gap: "2%",
                      textAlign: "start",
                    }}
                  >
                    <div
                      style={{
                        width: "49%",
                        border: "1px solid #465533",
                        padding: "15px",
                      }}
                    >
                      <h1 style={{ padding: "0", margin: "0" }}>-</h1>
                      {dish?.redItems?.map((red_item, index) => {
                        return (
                          <h4 style={{ padding: "0", margin: "0" }} key={index}>
                            {red_item?.itemName}
                          </h4>
                        );
                      })}
                    </div>
                    <div
                      style={{
                        width: "49%",
                        border: "1px solid #465533",
                        padding: "15px",
                      }}
                    >
                      <h1 style={{ padding: "0", margin: "0" }}>+</h1>
                      {dish?.greenItems?.map((green_item, index) => {
                        return (
                          <h4 style={{ padding: "0", margin: "0" }} key={index}>
                            {green_item?.itemName}
                          </h4>
                        );
                      })}
                    </div>
                  </div>
                ) : null}
                {dish?.ingredients?.map((ing, key) => {
                  return (
                    <div className="ing-name px-2" key={key}>
                      <span className="d-block">
                        <span>{parseInt(ing?.quantity)} -</span> {ing?.name}
                      </span>
                    </div>
                  );
                })}
              </>
            </div>
            <iframe
              ref={printref}
              style={{ height: "0px", width: "0px", position: "absolute" }}
            ></iframe>
          </div>
        </div>
      </div>
    </>
  );
};
// };

export default Ocard;
