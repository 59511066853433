import { MERGE_MODAL, REMOVE_MERGE_SELECTION } from "../../../../store/types";

///// =============== Merge ==================
export const setTableToMerge = (dispatch, activeSession) => {
  dispatch({
    type: MERGE_MODAL,
    payload: {
      firstTableName: {
        id: activeSession?._id,
        number: activeSession?.table_id,
        name: activeSession?.table_name,
        parentId: activeSession?.parentId
          ? activeSession?.parentId
          : activeSession?._id,
      },
    },
  });
};

//REMOVE SELECTION

export const removeSetSelection = (dispatch) => {
  dispatch({
    type: REMOVE_MERGE_SELECTION,
  });
};
