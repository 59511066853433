import {
  FETCH_STATIONS_REQUEST,
  FETCH_STATIONS_SUCCESS,
  FETECH_STATIONS_FAIL,
} from "../Type/stationTypes";
import { RepositoryFactory } from "../../repository/RepositoryFactory";
var stationRepository = RepositoryFactory.get("station");

export const getAllStations = () => async (dispatch) => {
  try {
    dispatch({
      type: FETCH_STATIONS_REQUEST,
    });
    const res = await stationRepository.getStation();
    if (res?.data?.success) {
      dispatch({
        type: FETCH_STATIONS_SUCCESS,
        payload: res.data.data,
      });
    } else {
      dispatch({
        type: FETECH_STATIONS_FAIL,
        payload: res.data?.data,
      });
    }
  } catch (error) {
    console.log(error);
    dispatch({
      type: FETECH_STATIONS_FAIL,
      payload: error,
    });
  }
};
