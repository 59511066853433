import { LOGIN_SUCCESS, LOGOUT, LOGIN_REQUESTED, LOGIN_FAIL } from "../types";
import { RepositoryFactory } from "../../repository/RepositoryFactory";
var authRepository = RepositoryFactory.get("auth");

export const login = (payload) => async (dispatch) => {
  try {
    dispatch({ type: LOGIN_REQUESTED });
    let { data } = await authRepository.getAuth(payload);
    dispatch({
      type: LOGIN_SUCCESS,
      payload: data,
    });
    return {
      payload: data,
    };
  } catch (err) {
    dispatch({ type: LOGIN_FAIL });
    console.log("error", err);
    alert(err.response.data.message);
    return {
      message: err.response.data.message,
    };
  }
};

export const logout = () => async (dispatch) => {
  window.localStorage.clear();
  dispatch({
    type: LOGOUT,
  });
  dispatch({
    type: "LOG_OUT",
  });
};

// export const getBusiness = () => async (dispatch) => {
//   try {
//     dispatch({ type: FETCH_BUSINESSES_REQUEST, payload: true });
//     let res = await authRepository.getBusinessId();
//     dispatch({ type: FETCH_BUSINESSES_REQUEST, payload: false });
//     if (!res?.data.success) {
//       dispatch({
//         type: GET_BUSINESSES_FAIL,
//         payload: res.data,
//       });
//     }
//     return {
//       payload: res.data,
//     };
//   } catch (err) {
//     dispatch({
//       type: GET_BUSINESSES_FAIL,
//       payload: err,
//     });
//   }
// };

// export const setBusinesse = (data) => async (dispatch) => {
//   try {
//     localStorage.setItem("blId", data);
//     dispatch({
//       type: SET_BUSINESS,
//       payload: data,
//     });
//   } catch (e) {
//     console.log(e);
//   }
// };
