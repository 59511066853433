import React from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  CLEAR_ID,
  MANAGE_ITEM_CLOSE_MODAL,
  STATION_OPEN_MODAL,
} from "../../../../store/types";
import ModalInterface from "../../../ModalInterface";

const MangeModal = () => {
  const dispatch = useDispatch();
  const manage_Modal = useSelector((state) => state?.manage?.manageModal);
  return (
    <>
      <ModalInterface
        display={manage_Modal}
        closeEvent={() => {
          dispatch({ type: MANAGE_ITEM_CLOSE_MODAL });
          dispatch({ type: CLEAR_ID });
        }}
        className={"text-center"}
        size={"sm"}
        content={
          <>
            <div className="d-flex justify-content-center">
              <div className="mt-2 ">
                {/* <div
                  className="btn-yellow-modal mt-2 position-absoulte"
                  onClick={() => {
                    dispatch({ type: MANAGE_ITEM_CLOSE_MODAL });
                  }}
                >
                  <img
                    src="/img/icons/DENY_BLACK.png"
                    alt="reject"
                    className="mt-2 "
                    height={25}
                  />
                  <span>CANCEL</span>
                </div> */}

                <div
                  className="btn-yellow-modal mt-2"
                  onClick={() => {
                    // SetChangeSt(true);
                    dispatch({ type: STATION_OPEN_MODAL });
                    dispatch({ type: MANAGE_ITEM_CLOSE_MODAL });
                  }}
                >
                  <img
                    src="/img/icons/SET_BLACK.png"
                    alt="reject"
                    className="mt-2"
                    height={30}
                  />
                  <span>Modify</span>
                </div>
              </div>
            </div>
          </>
        }
        title={
          <div className="title ">
            <span className="mx-2">Action</span>
          </div>
        }
        footer={false}
      />
    </>
  );
};

export default MangeModal;
