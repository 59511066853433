import {
  FETCH_BUSINESSES_REQUEST,
  FETCH_BUSINESSES_SUCCESS,
  GET_BUSINESSES_FAIL,
  SET_BUSINESS,
} from "../types";
import { RepositoryFactory } from "../../repository/RepositoryFactory";
var authRepository = RepositoryFactory.get("auth");

export const getBusiness = () => async (dispatch) => {
  try {
    dispatch({ type: FETCH_BUSINESSES_REQUEST });
    let res = await authRepository.getBusinessId();
    if (res?.data?.success) {
      dispatch({
        type: FETCH_BUSINESSES_SUCCESS,
        payload: res?.data?.data,
      });
    } else {
      dispatch({
        type: GET_BUSINESSES_FAIL,
        payload: res.data,
      });
    }
  } catch (err) {
    dispatch({
      type: GET_BUSINESSES_FAIL,
      payload: err,
    });
  }
};

export const setBusinesse = (data) => async (dispatch) => {
  try {
    console.log(data);
    localStorage.setItem("blId", data);
    dispatch({
      type: SET_BUSINESS,
      payload: data,
    });
  } catch (e) {
    console.log(e);
  }
};
