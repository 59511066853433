import { SESSIONS_FAIL, SESSIONS_REQUEST, TABLE_MERGE } from "../types";
import {
  FETCH_ALL_TABLE_REQUEST,
  FETCH_ALL_TABLE_SUCCESS,
  FETCH_ALL_TABLE_FAIL,
  CHANGE_TABLE_SESSION_REQUEST,
  CHANGE_TABLE_SESSION_SUCCESS,
  CHANGE_TABLE_SESSION_FAIL,
  FETCH_ALL_SESSIONS_REQUEST,
  FETCH_ALL_SESSIONS_SUCCESS,
  FETCH_ALL_SESSIONS_FAIL,
  DELETE_SESSIONS_REQUEST,
  DELETE_SESSIONS_SUCCESS,
  DELETE_SESSIONS_FAIL,
  PAY_WAITER_REQUEST,
  PAY_WAITER_SUCCESS,
  PAY_WAITER_FAIL,
  SELECT_STATION_FAIL,
  // CHANGE_BUMP_TABLE_REQUEST,
  // CHANGE_BUMP_TABLE_STATUS_SUCCESS,
  // CHANGE_BUMP_TABLE_STATUS_FAIL,
  // TABLE_SERVE_UPDATE_REQUEST,
  // TABLE_SERVE_UPDATE_SUCCESS,
  // TABLE_SERVE_UPDATE_FAIL,
  SINGLE_TABLE_LOADING,
  CLEAR_SINGLE_TABLE_LOADING,
  TABLE_SERVE_SUCCESS,
  TABLE_SERVE_REQUEST,
  TABLE_SERVE_FAIL,
} from "../Type/tabelSessionTypes";
import { RepositoryFactory } from "../../repository/RepositoryFactory";
import { socket } from "../../services/services.sockets";
import { UPDATE_PAYMENT_STATUS } from "../Type/orderTypes";

var tableRepository = RepositoryFactory.get("table");

export const getAllTableAction = (payload) => async (dispatch) => {
  try {
    dispatch({ type: FETCH_ALL_TABLE_REQUEST });
    const response = await tableRepository.getAllTables(payload);
    if (response.data.success) {
      dispatch({
        type: FETCH_ALL_TABLE_SUCCESS,
        payload: response?.data?.data,
      });
    } else {
      dispatch({
        type: FETCH_ALL_TABLE_FAIL,
        payload: response?.data?.data,
      });
    }
  } catch (error) {
    console.log("Error fetching Table Sessions", error);
    dispatch({
      type: FETCH_ALL_TABLE_FAIL,
      payload: error,
    });
  }
};

export const SetSingleTableLoader = (table) => async (dispatch) => {
  dispatch({ type: SINGLE_TABLE_LOADING, payload: table });
};

export const getAllSessions = (payload) => async (dispatch) => {
  try {
    dispatch({ type: FETCH_ALL_SESSIONS_REQUEST });
    const response = await tableRepository.getAllTableSessions(payload);
    dispatch({
      type: FETCH_ALL_SESSIONS_SUCCESS,
      payload: response?.data?.data,
    });
  } catch (error) {
    console.log("Error fetching Table Sessions", error);
    dispatch({
      type: FETCH_ALL_SESSIONS_FAIL,
      payload: error,
    });
  }
};
export const selectStation = (payload) => async (dispatch) => {
  try {
    let res = await tableRepository.selectStation(payload);
    if (!res?.data?.success) {
      dispatch({
        type: SELECT_STATION_FAIL,
        payload: res?.data?.data,
      });
    }
  } catch (error) {
    dispatch({ type: SELECT_STATION_FAIL, payload: error });
  }
};
export const UpdataTableSession = (payload) => async (dispatch) => {
  try {
    dispatch({ type: CHANGE_TABLE_SESSION_REQUEST });
    let res = await tableRepository.updataTbSession(payload);
    if (res?.data) {
      dispatch({
        type: CHANGE_TABLE_SESSION_SUCCESS,
        payload: res?.data,
      });
    }
  } catch (error) {
    dispatch({ type: CHANGE_TABLE_SESSION_FAIL, payload: error });
  }
};
export const mergeSession = (payload) => async (dispatch) => {
  try {
    dispatch({ type: SESSIONS_REQUEST });
    let { data } = await tableRepository.mergeSessions(payload);
    dispatch({
      type: TABLE_MERGE,
      payload: data,
    });
  } catch (error) {
    alert("Error !" + error?.response?.data?.message);
    dispatch({ type: SESSIONS_FAIL, payload: error });
  }
};
export const deleteSession = (id) => async (dispatch) => {
  try {
    dispatch({ type: DELETE_SESSIONS_REQUEST });
    let res = await tableRepository.removeSession(id);
    if (res?.data?.success) {
      alert(res.data.message);
      dispatch({
        type: DELETE_SESSIONS_SUCCESS,
        payload: id,
      });
      dispatch({ type: CLEAR_SINGLE_TABLE_LOADING });
      socket.emit("tableCreated", { deleted: true, id: id });
    } else {
      dispatch({ type: DELETE_SESSIONS_FAIL, payload: res?.data.data });
    }
  } catch (error) {
    alert("Error !" + error?.response?.data?.message);
    dispatch({ type: DELETE_SESSIONS_FAIL, payload: error });
  }
};
export const payWaiter = (payload) => async (dispatch) => {
  if (!payload.tableNumber || !payload.businessLocationId) {
    alert("Make sure you have: \n 1- Add items to cart \n 2- Joined a table");
    return window.location.replace("/home");
  }
  try {
    dispatch({ type: PAY_WAITER_REQUEST });
    let res = await tableRepository.payWaiterOrder(payload);
    if (res?.data?.success) {
      dispatch(getAllSessions(payload.businessLocationId));
      dispatch({ type: PAY_WAITER_SUCCESS });
      dispatch({ type: UPDATE_PAYMENT_STATUS, payload });
      alert("Paid Success Fully");
      return res;
    } else {
      alert("order failed");
      dispatch({ type: PAY_WAITER_FAIL, payload: res?.data?.data });
    }
  } catch (err) {
    alert("Order Failed:" + " " + err?.response?.data?.message);
    dispatch({ type: PAY_WAITER_FAIL, payload: err });
  }
};
export const serveStatusUpdateSession = (payload) => async (dispatch) => {
  dispatch({ type: TABLE_SERVE_REQUEST });
  try {
    let res = await tableRepository.updateServeStation(payload);
    if (res) {
      dispatch({ type: TABLE_SERVE_SUCCESS, payload: payload });
    }
  } catch (error) {
    dispatch({ type: TABLE_SERVE_FAIL, payload: error });
  }
};
