import React from "react";
import { Badge } from "reactstrap";
import ModalInterface from "../../../ModalInterface";
import "../../../../assets/css/changeStations.css";
import { useSelector, useDispatch } from "react-redux";
import { useState } from "react";
import { useEffect } from "react";
import { changeOrderStAction } from "../../../../store/actions/orderAction";
import { STATION_CLOSE_MODAL } from "../../../../store/types";

const ChangeStationModal = () => {
  const userStations = useSelector(
    (state) => state?.authUser?.user?.data?.stations
  );
  const cardIDForSt = useSelector((state) => state?.info?.id?.Id);
  const cardStation = useSelector((state) => state?.info?.id?.stationName);
  const stationModal = useSelector((state) => state?.modal?.modal);
  const dispatch = useDispatch();
  const [stNameToBe, setStNameToBe] = useState(cardStation?.station);
  const [stChoosed, setStChossed] = useState(false);
  const changeStation = () => {
    if (cardStation?.station !== stNameToBe && cardIDForSt !== undefined) {
      dispatch(
        changeOrderStAction({
          order_id: cardIDForSt,
          sku: cardStation?.itemSku,
          station: stNameToBe,
        })
      );
      // SetChangeSt(false);
      dispatch({ type: STATION_CLOSE_MODAL });
    } else {
      setStChossed(true);
    }
  };

  useEffect(() => {
    setStNameToBe(cardStation?.station);
  }, [cardStation]);

  // const removeItemFunc = () => {
  //   let ans = window.confirm("Do you want to remove this item?");
  //   if (cardStation?._id && ans === true) {
  //     dispatch(
  //       removeItemAction({
  //         orderId: cardIDForSt,
  //         saleEntryID: cardStation._id,
  //       })
  //     );
  //     dispatch({ type: STATION_CLOSE_MODAL });
  //   }
  // };

  return (
    <ModalInterface
      display={stationModal}
      //   setDisplay={setDisplay}
      closeEvent={() => {
        dispatch({ type: STATION_CLOSE_MODAL });
        setStNameToBe(cardStation?.station);
      }}
      className={"text-center"}
      size={"md"}
      content={
        <>
          <div className="stations-input ">
            {userStations?.map((item) => {
              return (
                <>
                  {item === "all" ? (
                    ""
                  ) : item === stNameToBe ? (
                    <Badge href="#" color="" pill className="muted p-2 mx-2">
                      <span className="text-muted">{item}</span>
                    </Badge>
                  ) : (
                    <Badge
                      href="#"
                      color="info"
                      className="p-2 m-2"
                      pill
                      onClick={() => {
                        setStNameToBe(item);
                        setStChossed(false);
                      }}
                    >
                      <span>{item}</span>
                    </Badge>
                  )}
                </>
              );
            })}
          </div>
          {stChoosed && (
            <div className="d-flex justify-content-center">
              <p className="text-danger">This station already set.</p>
            </div>
          )}
          <div className="d-flex justify-content-center">
            <div className="mt-2 ">
              {/* <div
                className="btn-yellow-modal mt-2 position-absoulte"
                onClick={() => {
                  // SetChangeSt(false);
                  dispatch({type:STATION_CLOSE_MODAL})
                }}
              >
                <img
                  src="/img/icons/DENY_BLACK.png"
                  alt="reject"
                  className="mt-2 "
                  height={25}
                />
                <span>CANCEL</span>
              </div> */}

              <div
                className="btn-yellow-modal mt-2"
                onClick={() => changeStation()}
              >
                <img
                  src="/img/icons/CHECK_BLACK.png"
                  alt="reject"
                  className="mt-2"
                  height={30}
                />
                <span>CONFIRM</span>
              </div>
            </div>
          </div>
          {/* <Card body className="mt-3">
            <CardTitle tag="h5" className="text-danger">
              Remove Item
            </CardTitle>
            <CardText className="d-flex justify-content-center">
              Do you want to remove this item?
            </CardText>
            <div className="d-flex justify-content-center">
              <div className="btn-yellow-modal mt-2" onClick={()=>{removeItemFunc()}}>
                <img
                  src="/img/icons/CHECK_BLACK.png"
                  alt="reject"
                  className="mt-2"
                  height={30}
                />
                <span>Remove</span>
              </div>
            </div>
          </Card> */}
        </>
      }
      title={<span>Change station of this dish.</span>}
    />
  );
};

export default ChangeStationModal;
