import { MANAGE_ITEM_CLOSE_MODAL, MANAGE_ITEM_OPEN_MODAL } from "../types";
const initialState = {
  manageModal: false,
};

export const manage_modalReducer = (state = initialState, action) => {
  switch (action.type) {
    case MANAGE_ITEM_OPEN_MODAL:
      return {
        ...state,
        manageModal: true,
      };
    case MANAGE_ITEM_CLOSE_MODAL:
      return {
        ...state,
        manageModal: false,
      };
    default:
      return state;
  }
};
