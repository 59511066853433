import { socket } from "../services/services.sockets";
import { cookingProcessUpdate } from "../store/actions/orderAction";
export const SingleCardCookingFunc = ({
  dispatch,
  order,
  dish,
  sessions,
  setDataLoading,
  setListenDataChange,
}) => {
  setDataLoading(true);
  setListenDataChange(true);
  dispatch(
    cookingProcessUpdate({
      order_id: order?._id,
      sales_entry_id: dish?._id,
      dish_status: dish?.status === "pending" ? "cooking" : "cooked",
      sessionId: order?.sessionId,
      sessions,
    })
  ).then(() => {
    socket.emit("processing", {});
    setDataLoading(false);
  });
};
