// import * as ot from '../Type/orderTypes';
import {
  ORDER_FAIL,
  FETCH_SESSION_ORDER,
  FETCH_SESSION_ORDER_FAIL,
  BAR_LOADER,
  FETCH_ORDERS_REQUEST,
  FETCH_ORDERS_SUCCESS,
  FETCH_ORDERS_FAIL,
  FETCH_SINGLE_ORDER_REQUEST,
  FETCH_SINGLE_ORDER_SUCCESS,
  FETCH_SINGLE_ORDER_FAIL,
  SET_DISH_PERIORITY_REQUEST,
  SET_DISH_PERIORITY_SUCCESS,
  SET_DISH_PERIORITY_FAIL,
  SET_ORDER_PERIORITY_REQUEST,
  SET_ORDER_PERIORITY_SUCCESS,
  SET_ORDER_PERIORITY_FAIL,
  SET_DISH_STATION_REQUEST,
  SET_DISH_STATION_SUCCESS,
  SET_DISH_STATION_FAIL,
  SET_ORDER_CANCEL_REQUEST,
  SET_ORDER_CANCEL_SUCCESS,
  SET_ORDER_CANCEL_FAIL,
  SET_ITEM_CANCEL_REQUEST,
  SET_ITEM_CANCEL_SUCCESS,
  SET_ITEM_CANCEL_FAIL,
  FETCH_COURSES_REQUEST,
  FETCH_COURSES_SUCCESS,
  FETCH_COURSES_FAIL,
  ORDER_COOKING_PROCESS_REQUEST,
  ORDER_COOKING_PROCESS_SUCCESS,
  ORDER_COOKING_PROCESS_FAIL,
  SET_ORDER_BUMP_REQUEST,
  SET_ORDER_BUMP_SUCCESS,
  SET_ORDER_BUMP_FAIL,
  COOKING_PROCESS_UPDATE_REQUEST,
  COOKING_PROCESS_UPDATE_SUCCESS,
  COOKING_PROCESS_UPDATE_FAIL,
  SERVE_ORDER_REQUEST,
  SERVE_ORDER_SUCCESS,
  UPDATE_PAYMENT_STATUS,
} from "../Type/orderTypes";
// import { UpdateReducerAfterCooking } from "../updateReducer/cookingUpdateReducer";
import { updateServeReducer } from "../updateReducer/serveStatusUpdateReducer";
import { updateOrderBySession } from "../updateReducer/sessionOrderReducer";
const initialState = {
  orders: [],
  parentOrders: [],
  loading: false,
  dishStationChangeLoader: false,
  cookingProcessLoader: false,
  orderPriortyChangeLoader: false,
  cancelOrderLoader: false,
  removeItemLoading: false,
  sessionOrders: [],
  dishPeriorityLoading: false,
  bumpOrderLoading: false,
  singleLoading: false, //for single orders
  courses: [],
  fetchCoursesLoading: false,
  barLoading: false,
  cookingUpdateLoader: false,
  cookingUpdateStatus: [],
  errors: {},
  serveOrderStatusLoader: false,
};

// function selectedDishUpdate(orders, payload) {
//   let allOrders = orders;
//   let selectedOrderIndex = allOrders?.findIndex(
//     (item) => item?._id === payload?.order_id
//   );
//   let selectedDishIndex = allOrders[
//     selectedOrderIndex
//   ]?.salesEntries?.findIndex((item) => item?._id === payload?.item_id);
//   allOrders[selectedOrderIndex].salesEntries[selectedDishIndex].priority =
//     payload?.priority;
//   return colorChnge(allOrders, payload);
// }
// function selectedOrderUpdate(orders, payload) {
//   let allOrders = orders;
//   let selectedOrderIndex = allOrders?.findIndex(
//     (item) => item?._id === payload?.order_id
//   );
//   allOrders[selectedOrderIndex].priority = payload?.priority;
//   return colorChnge(allOrders, payload);
// }
// function selectedDishStationUpdate(orders, payload) {
//   let allOrders = orders;
//   let selectedOrderIndex = allOrders?.findIndex(
//     (item) => item?._id === payload?.order_id
//   );
//   let selectedDishIndex = allOrders[
//     selectedOrderIndex
//   ]?.salesEntries?.findIndex((item) => item?.itemSku === payload?.sku);
//   allOrders[selectedOrderIndex].salesEntries[selectedDishIndex].station =
//     payload?.station;
//   return allOrders;
// }
// function deleteOrder(orders, payload) {
//   let allOrders = orders;
//   let selectedOrderIndex = allOrders?.findIndex(
//     (item) => item?._id === payload?.order_id
//   );
//   allOrders.splice(selectedOrderIndex, 1);
//   return allOrders;
// }
// function deleteOrderItem(orders, payload) {
//   let allOrders = orders;
//   let selectedOrderIndex = allOrders?.findIndex(
//     (item) => item?._id === payload?.orderId
//   );

//   let selectedDishIndex = allOrders[
//     selectedOrderIndex
//   ]?.salesEntries?.findIndex((item) => item?._id === payload?.saleEntryID);
//   allOrders[selectedOrderIndex]?.salesEntries.splice(selectedDishIndex, 1);
//   return allOrders;
// }
function fetchSessionOrders(orders, payload) {
  let allOrders = orders;
  let filterdOrder = allOrders.filter((item) => item.sessionId === payload._id);
  return colorChnge(filterdOrder, payload);
}
function cookingProcessUpdate(orders, payload) {
  let allOrders = orders;
  let selectedOrderIndex = allOrders?.findIndex(
    (item) => item?._id === payload?.order_id
  );
  let selectedDishIndex = allOrders[
    selectedOrderIndex
  ]?.salesEntries?.findIndex((item) => item?._id === payload?.item_id);
  allOrders[selectedOrderIndex].salesEntries[selectedDishIndex].status =
    payload?.status;
  return colorChnge(allOrders, payload);
}
function bumpOrderUpdate(orders, payload) {
  let allOrders = orders;
  let selectedOrderIndex = allOrders?.findIndex(
    (item) => item?._id === payload?.order_id
  );
  let selectedDishIndex = allOrders[
    selectedOrderIndex
  ]?.salesEntries?.findIndex((item) => item?._id === payload?.item_id);
  allOrders[selectedOrderIndex].salesEntries[selectedDishIndex].bumped =
    payload?.bumped;
  allOrders[selectedOrderIndex].salesEntries[selectedDishIndex].bumpStation =
    payload?.bumpStation;
  return colorChnge(allOrders, payload);
}
function colorChnge(orders, payload) {
  let allOrders = orders;
  let selectedOrderIndex = allOrders?.findIndex(
    (item) => item?._id === payload?.order_id
  );
  let item = allOrders[selectedOrderIndex];
  if (item?._id) {
    if (item?.salesEntries?.every((x) => x?.status === "cooked")) {
      item.cookingColor = "bgplaygreen";
    }
    if (item?.salesEntries?.some((x) => x?.status === "cooking")) {
      item.cookingColor = "bgorder-yellow";
    } else {
      item.cookingColor = "bgOrder-light-Gray";
    }
    //Bump Condition
    if (
      item?.salesEntries?.some(
        (x) => x?.bumped === true && x.serve_status === false
      )
    ) {
      item.bumpColor = "bgplaygreen";
    } else {
      item.bumpColor = "bgOrder-light-Gray";
    }
    allOrders[selectedOrderIndex] = item;
  }

  return allOrders;
}
export const updatePaymentStatus = (orders, payload) => {
  let allOrders = orders;
  let selectedOrderIndex = allOrders?.findIndex(
    (item) => item?.sessionId === payload?.sessionID
  );
  allOrders[selectedOrderIndex].payment_status = "paid";
  return allOrders;
};
export const orderReducer = (state = initialState, action) => {
  var allErrors = state.errors;
  var allOrders = state.orders;

  switch (action.type) {
    // order
    case FETCH_ORDERS_REQUEST:
      return {
        ...state,
        singleLoading: false,
        loading: false,
        isAuthenticated: false,
      };
    case FETCH_ORDERS_SUCCESS:
      action.payload?.map((item) => {
        if (item?.salesEntries?.every((x) => x?.status === "cooked")) {
          item.cookingColor = "bgplaygreen";
        }
        if (item?.salesEntries?.some((x) => x?.status === "cooking")) {
          item.cookingColor = "bgorder-yellow";
        } else {
          item.cookingColor = "bgOrder-light-Gray";
        }
        //Bump Condition
        if (
          item?.salesEntries?.some(
            (x) => x?.bumped === true && x.serve_status === false
          )
        ) {
          item.bumpColor = "bgplaygreen";
        } else {
          item.bumpColor = "bgOrder-light-Gray";
        }
      });
      var childOrders = action.payload.filter(
        (_item) => _item.isParent == false
      );
      var parentOrders = action.payload.filter(
        (_item) => _item.isParent == true
      );
      return {
        ...state,
        loading: false,
        singleLoading: false,
        orders: childOrders,
        parentOrders: parentOrders,
      };
    case FETCH_ORDERS_FAIL:
      allErrors[FETCH_ORDERS_FAIL] = action.payload;
      return {
        ...state,
        loading: false,
        orders: [],
        errors: allErrors,
      };
    // single order
    case FETCH_SINGLE_ORDER_REQUEST:
      return {
        ...state,
        singleLoading: true,
        isAuthenticated: false,
      };
    case FETCH_SINGLE_ORDER_SUCCESS:
      var temporaryOrder = [];
      temporaryOrder = JSON.parse(JSON.stringify(allOrders));
      var index = temporaryOrder.findIndex(
        (item) => item?._id === action?.payload._id
      );
      temporaryOrder[index] = action.payload;
      return {
        ...state,
        singleLoading: false,
        orders: temporaryOrder,
      };
    case FETCH_SINGLE_ORDER_FAIL:
      allErrors[FETCH_SINGLE_ORDER_FAIL] = action.payload;
      return {
        ...state,
        singleLoading: false,
        errors: allErrors,
      };
    // dish station change
    case SET_DISH_STATION_REQUEST:
      return {
        ...state,
        dishStationChangeLoader: true,
      };
    case SET_DISH_STATION_SUCCESS:
      // var allUpdatedStationOrders = selectedDishStationUpdate(
      //   state.orders,
      //   action.payload
      // );
      return {
        ...state,
        dishStationChangeLoader: false,
        // orders: allUpdatedStationOrders,
      };
    case SET_DISH_STATION_FAIL:
      allErrors[SET_DISH_STATION_FAIL] = action.payload;
      return {
        ...state,
        dishStationChangeLoader: false,
        errors: allErrors,
      };
    // cooking process
    case ORDER_COOKING_PROCESS_REQUEST:
      return {
        ...state,
        cookingProcessLoader: true,
        singleLoading: true,
      };
    case ORDER_COOKING_PROCESS_SUCCESS:
      var updatedCookingOrders = cookingProcessUpdate(
        state.orders,
        action.payload
      );
      return {
        ...state,
        cookingProcessLoader: false,
        singleLoading: false,
        orders: updatedCookingOrders,
      };
    case ORDER_COOKING_PROCESS_FAIL:
      allErrors[ORDER_COOKING_PROCESS_FAIL] = action.payload;
      return {
        ...state,
        cookingProcessLoader: false,
        singleLoading: false,
        errors: allErrors,
      };
    // order periority

    case SET_ORDER_PERIORITY_REQUEST:
      return {
        ...state,
        orderPriortyChangeLoader: true,
      };
    case SET_ORDER_PERIORITY_SUCCESS:
      // var allUpdatedOrders = selectedOrderUpdate(state.orders, action.payload);
      return {
        ...state,
        orderPriortyChangeLoader: false,
        // orders: allUpdatedOrders,
      };
    case SET_ORDER_PERIORITY_FAIL:
      allErrors[SET_ORDER_PERIORITY_FAIL] = action.payload;
      return {
        ...state,
        orderPriortyChangeLoader: false,
        errors: allErrors,
      };

    // order cancel
    case SET_ORDER_CANCEL_REQUEST:
      return {
        ...state,
        cancelOrderLoader: true,
      };
    case SET_ORDER_CANCEL_SUCCESS:
      // var afterDeletedOrder = deleteOrder(state.orders, action.payload);
      return {
        ...state,
        cancelOrderLoader: false,
        // orders: afterDeletedOrder,
      };
    case SET_ORDER_CANCEL_FAIL:
      allErrors[SET_ORDER_CANCEL_FAIL] = action.payload;

      return {
        ...state,
        cancelOrderLoader: false,
        errors: allErrors,
      };
    case ORDER_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    // cancel item
    case SET_ITEM_CANCEL_REQUEST:
      return {
        ...state,
        removeItemLoading: true,
      };
    case SET_ITEM_CANCEL_SUCCESS:
      // var afterDeletedItemOrder = deleteOrderItem(state.orders, action.payload);
      return {
        ...state,
        removeItemLoading: false,
        // orders: afterDeletedItemOrder,
      };
    case SET_ITEM_CANCEL_FAIL:
      allErrors[SET_ITEM_CANCEL_FAIL] = action.payload;
      return {
        ...state,
        removeItemLoading: false,
        errors: allErrors,
      };

    // session orders
    case FETCH_SESSION_ORDER:
      var allSessionOrders = null;
      if (action.payload.isParent) {
        allSessionOrders = fetchSessionOrders(
          state.parentOrders,
          action.payload
        );
      } else {
        allSessionOrders = fetchSessionOrders(state.orders, action.payload);
      }

      return {
        ...state,
        sessionOrders: allSessionOrders,
      };
    case FETCH_SESSION_ORDER_FAIL:
      allErrors[FETCH_SESSION_ORDER_FAIL] = action.payload;
      return {
        ...state,
        sessionOrders: [],
        errors: allErrors,
      };
    // dish periority
    case SET_DISH_PERIORITY_REQUEST:
      return {
        ...state,
        dishPeriorityLoading: true,
      };
    case SET_DISH_PERIORITY_SUCCESS:
      // var updatedOrders = selectedDishUpdate(state.orders, action.payload);
      return {
        ...state,
        dishPeriorityLoading: false,
        // orders: updatedOrders,
      };
    case SET_DISH_PERIORITY_FAIL:
      allErrors[SET_DISH_PERIORITY_FAIL] = action.payload;
      return {
        ...state,
        dishPeriorityLoading: false,
        errors: allErrors,
      };
    // bump order
    case SET_ORDER_BUMP_REQUEST:
      return {
        ...state,
        singleLoading: true,
        bumpOrderLoading: false,
      };
    case SET_ORDER_BUMP_SUCCESS:
      var allOrdersAfterBumped = bumpOrderUpdate(state.orders, action.payload);
      return {
        ...state,
        bumpOrderLoading: false,
        singleLoading: false,
        orders: allOrdersAfterBumped,
      };
    case SET_ORDER_BUMP_FAIL:
      allErrors[SET_ORDER_BUMP_FAIL] = action.payload;
      return {
        ...state,
        bumpOrderLoading: false,
        singleLoading: false,
        errors: allErrors,
      };
    // courses
    case FETCH_COURSES_REQUEST:
      return {
        ...state,
        fetchCoursesLoading: true,
      };
    case FETCH_COURSES_SUCCESS:
      return {
        ...state,
        fetchCoursesLoading: false,
        courses: action.payload,
      };
    case FETCH_COURSES_FAIL:
      allErrors[FETCH_COURSES_FAIL] = action.payload;
      return {
        ...state,
        fetchCoursesLoading: false,
        errors: allErrors,
      };
    // bar loader
    case BAR_LOADER:
      return {
        ...state,
        barLoading: action.payload,
      };

    case COOKING_PROCESS_UPDATE_REQUEST:
      return {
        ...state,
        cookingUpdateLoader: true,
      };
    case COOKING_PROCESS_UPDATE_SUCCESS:
      // var allUpdatedCooking = UpdateReducerAfterCooking(
      //   state.orders,
      //   action.payload
      // );
      var updateSessionOrder = updateOrderBySession(
        state.sessionOrders,
        action.payload
      );
      return {
        ...state,
        cookingUpdateLoader: false,
        // orders: allUpdatedCooking,
        sessionOrders: [updateSessionOrder],
      };
    case COOKING_PROCESS_UPDATE_FAIL:
      return {
        ...state,
        cookingUpdateLoader: false,
        error: action.payload,
      };
    case SERVE_ORDER_REQUEST:
      return {
        ...state,
        // serveOrderStatusLoader: true,
      };
    case SERVE_ORDER_SUCCESS:
      var updateorder = updateServeReducer(state.orders, action.payload);
      return {
        ...state,
        cookingUpdateLoader: false,
        orders: updateorder,
      };
    case UPDATE_PAYMENT_STATUS:
      var paymentUpdatedOrder = updatePaymentStatus(
        state.orders,
        action.payload
      );
      return {
        ...state,
        orders: paymentUpdatedOrder,
      };
    default:
      return state;
  }
};
