import {
  FETCH_SERVICES_NOTIFICATIONS_REQUEST,
  FETCH_SERVICES_NOTIFICATIONS_SUCCESS,
  FETCH_SERVICES_NOTIFICATIONS_FAIL,
  SAVE_SERVICE_NOTIFICATION_FAIL,
} from "../Type/ServicesTypes";
import { RepositoryFactory } from "../../repository/RepositoryFactory";
var ServiceNotificationRepository = RepositoryFactory.get("service");

export const getServiceNotification = (payload) => async (dispatch) => {
  try {
    dispatch({ type: FETCH_SERVICES_NOTIFICATIONS_REQUEST });
    const res = await ServiceNotificationRepository.getServiceNotifications(
      payload
    );
    dispatch({
      type: FETCH_SERVICES_NOTIFICATIONS_SUCCESS,
      payload: res?.data,
    });
  } catch (error) {
    console.log(error);
    dispatch({
      type: FETCH_SERVICES_NOTIFICATIONS_FAIL,
      payload: error,
    });
  }
};

export const saveServiceNotifications = (payload) => async (dispatch) => {
  try {
    const res = await ServiceNotificationRepository.addServiceNotifications(
      payload
    );
    if (!res?.data?.success) {
      dispatch({
        type: SAVE_SERVICE_NOTIFICATION_FAIL,
        payload: res?.data?.data,
      });
    }
  } catch (error) {
    console.log(error);
    dispatch({
      type: SAVE_SERVICE_NOTIFICATION_FAIL,
      payload: error,
    });
  }
};
