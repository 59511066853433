import React, { useState } from "react";
import { Outlet } from "react-router-dom";
import BottomNav from "../../components/Dashboard/NavBars/BottomNavBar/BottomNav";
import TopNav from "../../components/Dashboard/NavBars/TopNav";
export default function EmployeeDashboard() {
  const [active, setActive] = useState(-1);
  return (
    <div>
      <TopNav active={active} setActive={setActive} />
      <div className=" mb-5 pb-5 pt-5">
        <Outlet />
      </div>
      <div className="mt-5">
        <BottomNav active={active} setActive={setActive} />
      </div>
    </div>
  );
}
