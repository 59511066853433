import { Button, Col, Modal, ModalBody, ModalFooter, Row } from "reactstrap";

const PayWaiter = ({ setPaywaiterModal, notifyMsg, paywaiterModal }) => {
  return (
    <>
      <Modal
        centered
        // fullscreen="sm"
        size="md"
        onHide={() => {
          setPaywaiterModal(false);
        }}
        toggle={() => {
          setPaywaiterModal(false);
        }}
        isOpen={paywaiterModal}
        //   className={props.className}
      >
        <div className=" text-dark d-flex align-items-center justify-content-between py-1 px-3 border-bottom">
          <div className="d-flex align-items-center ">
            <img
              src="/img/icons/operate_logo.png"
              alt="operate-logo"
              height={50}
              width={30}
            />
            <h4 className="mb-0 mx-2">Pay Waiter Tip</h4>
          </div>
          <div onClick={() => setPaywaiterModal(false)}>
            {" "}
            <img src="/img/icons/DENY_BLACK.png" alt="reject" height={25} />
          </div>
        </div>

        <ModalBody>
          <Row>
            <Col lg="12" md="12" sm="12" xs="12">
              <div>
                Table No : <b>{notifyMsg?.tableNo}</b>
              </div>
              <div className="msg-wrapper mt-2 px-3">
                <p>{notifyMsg?.message}</p>
              </div>
            </Col>
          </Row>
        </ModalBody>

        <ModalFooter className="justify-content-center">
          <Button
            outline
            color="danger"
            onClick={() => {
              setPaywaiterModal(false);
            }}
          >
            Dismiss
          </Button>
          {/* <Button outline color="success" onClick={() => { saveNotfication(notifyMsg) }}>Save</Button>{' '} */}
        </ModalFooter>
      </Modal>
    </>
  );
};

export default PayWaiter;
