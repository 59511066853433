import {
  FETECH_RECIPES_SUCCESS,
  FETECH_RECIPES_FAIL,
  FETECH_RECIPES_REQUEST,
  FETECH_SINGLE_RECIPES_SUCCESS,
  FETECH_SINGLE_RECIPES_FAIL,
  RECIPE_RESET_REQUEST,
  FETECH_SINGLE_RECIPES_REQUEST,
} from "../types";

const initialState = {
  recipes: [],
  singleReceipe: [],
  loading: false,
  singleReceipeLoader: false,
  fetchRecipesError: [],
  fetchSingleReceipeError: [],
};

export const recipeReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETECH_RECIPES_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case FETECH_SINGLE_RECIPES_REQUEST:
      return {
        ...state,
        singleReceipeLoader: true,
      };
    case FETECH_RECIPES_SUCCESS:
      return {
        ...state,
        loading: false,
        recipes: action.payload,
      };
    case FETECH_SINGLE_RECIPES_SUCCESS:
      return {
        ...state,
        singleReceipeLoader: false,
        singleReceipe: action.payload,
      };

    case FETECH_RECIPES_FAIL:
      return {
        ...state,
        loading: false,
        fetchRecipesError: action.payload,
      };
    case FETECH_SINGLE_RECIPES_FAIL:
      return {
        ...state,
        singleReceipeLoader: false,
        fetchSingleReceipeError: action.payload,
      };

    case RECIPE_RESET_REQUEST:
      return {
        ...state,
        loading: true,
        recipes: [],
      };
    default:
      return state;
  }
};
