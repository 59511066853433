import { Spinner } from "reactstrap";

export function Loader() {
  return (
    <>
      {/* loader */}
      <div className="d-flex position-absolute top-50 start-50">
        <Spinner />
      </div>
    </>
  );
}
