import React from "react";
import { useDispatch } from "react-redux";
import { Col, Row } from "reactstrap";
import { getOrdersBySession } from "../../../../store/actions/orderAction";
import KellnerReadyCard from "./KellnerReadyCard";

const KellnerCooking = ({
  cookedOrders,
  setAlertMsg,
  setWarn,
  setSelectedSession,
}) => {
  const dispatch = useDispatch();
  return (
    <Row>
      {cookedOrders
        ?.filter((item) => item?.order_status === "cooking")
        .sort((a, b) => a.table_id - b.table_id)
        ?.map((cookingOrders, index) => {
          return (
            <Col sm="6" xs="6" md="2" lg="2" key={index}>
              <KellnerReadyCard
                cookOrder={cookingOrders}
                setAlertMsg={setAlertMsg}
                setWarn={setWarn}
                setSelectedSession={() => {
                  setSelectedSession(cookingOrders);
                  dispatch(getOrdersBySession(cookingOrders));
                }}
              />
            </Col>
          );
        })}
    </Row>
  );
};
export default KellnerCooking;
