import React, { useState } from "react";
import StartCookModal from "../Home/CookModal/startCook";
import OrderListCard from "../OrderListComponents/OrderListCard";
import { useSelector } from "react-redux";

const RowRecipe = ({ item, salesEntries }) => {
  const [current, setCurrent] = useState(new Date());
  const [selected, setSelected] = useState("");
  // const [cooked, setCooked] = useState(false);
  const [startCooking, setStartCooking] = useState(false);

  function refreshTime() {
    setCurrent(new Date());
  }
  setTimeout(refreshTime, 1000); //refresh every second
  const IdOrder = useSelector((st) => st?.info?.id.Id);
  const IdSaleEntry = useSelector((st) => st?.info?.id?.stationName);

  const proiOID = useSelector((st) => st?.info?.oId);

  const msConverter = (diff) => {
    var msec = Math.abs(diff);
    var hh = Math.floor(msec / 1000 / 60 / 60);
    msec -= hh * 1000 * 60 * 60;
    var mm = Math.floor(msec / 1000 / 60);
    msec -= mm * 1000 * 60;
    var ss = Math.floor(msec / 1000);
    msec -= ss * 1000;
    if (hh < 10) {
      hh = "0" + hh;
    }
    if (mm < 10) {
      mm = "0" + mm;
    }
    if (ss < 10) {
      ss = "0" + ss;
    }
    if (hh > 0) return hh + ":" + mm + ":" + ss;
    else return mm + ":" + ss;
  };

  const handlePlayModal = () => {
    if (IdSaleEntry !== "") {
      // setOrderId(item._id);
      setStartCooking(true);
    } else {
      alert("please select an order!");
    }
  };

  //to manage order colors
  const allCooked = salesEntries?.every((x) => {
    return x?.status === "cooked";
  });
  const anyCooking = salesEntries?.some((x) => {
    return x?.status === "cooking";
  });
  // const priorityOrder = (item) => {
  //   dispatch({
  //     type: ORDER_ID,
  //     payload: {
  //       Id: item?._id,
  //       priority: item?.priority,
  //     },
  //   });

  //   dispatch({ type: CLEAR_ID });
  // };

  return (
    <>
      <tr className="tdata-row ">
        <td>
          <div
            className={
              proiOID.Id === item?._id
                ? "btn-No mt-2 fs-4 btn-select"
                : "btn-No mt-2 fs-4 "
            }
            //  onClick={()=>{
            //   priorityOrder(item);
            //   setIdSelected(item?._id)
            // }}
          >
            {item?._id?.substr(item?._id.length - 4)}
          </div>
        </td>
        <td>
          <div
            className={`play-btn play-${
              allCooked ? "green" : anyCooking ? "yellow" : "gray"
            } mt-2`}
            onClick={() => {
              if (selected != null) handlePlayModal();
            }}
          >
            <img src="/img/icons/play.png" alt="play-icon" height="30px" />
          </div>
        </td>
        <div className="orderlist_detail  mt-2">
          <ul>
            {/* {item?.salesEntries?.map((itx) => { */}
            {salesEntries?.map((itx, index) => {
              return (
                <td className="d-flex flex-row" key={index}>
                  <OrderListCard
                    power={itx?.station?.toUpperCase()}
                    priority={item?.priority}
                    color={
                      itx.status === "cooked"
                        ? "powergreen"
                        : itx.status === "cooking"
                        ? "bgorder-yellow"
                        : "bgOrder-light-Gray"
                    }
                    item={itx}
                    orderId={item._id}
                    setSelected={setSelected}
                  />
                </td>
              );
            })}
          </ul>
        </div>
        <td>
          <div className="btn-waiting mt-2">
            {/* {delayFlag && elapsedTime > maxPrep
              ? timeFormatter(elapsedTime - maxPrep)
              : "00:00"} */}
            {msConverter(current - new Date(item?.createdAt))}
          </div>
        </td>
        <td>
          <div
            className={`play-btn play-${
              allCooked ? "green" : anyCooking ? "yellow" : "gray"
            } mt-2`}
          >
            <img
              src="/img/icons/CHECK_WHITE.png"
              alt="play-icon"
              height="30px"
            />
          </div>
        </td>
      </tr>
      {/* 
      <CookModal
        cook={cooked}
        setCook={setCooked}
        cardId={item?._id}
        handleClose={() => {
          setSelected("")
        }}
      /> */}
      <StartCookModal
        startCooking={startCooking}
        setStartCooking={setStartCooking}
        cardId={IdOrder}
        saleEntry={IdSaleEntry}
        setSelected={setSelected}
      />
    </>
  );
};

export default RowRecipe;
