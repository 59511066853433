import React from "react";
import { Col, Row } from "reactstrap";
import ModalInterface from "../ModalInterface";
import "../../assets/css/selectStationMdoal.css";
import { useDispatch, useSelector } from "react-redux";
import { socket } from "../../services/services.sockets";
import { cookingProcessUpdate } from "../../store/actions/orderAction";
const SelectStation = ({
  stationSlectModal,
  setStationSelectModal,
  orderObj,
  setListenDataChange,
  setDataLoading,
}) => {
  const dispatch = useDispatch();

  const sessions = useSelector((state) => state?.tableSession?.sessions);
  const cookedFunc = (word) => {
    setListenDataChange(true);
    dispatch(
      cookingProcessUpdate({
        order_id: orderObj?.order?._id,
        sales_entry_id: orderObj?.dish?._id,
        dish_status: "cooked",
        bump: true,
        bumpStation: word,
        sessionId: orderObj?.order?.sessionId,
        sessions,
      })
    );
    setListenDataChange(false);
    socket.emit("processing", {});
    setStationSelectModal(false);
    setDataLoading(false);
  };
  return (
    <>
      <ModalInterface
        display={stationSlectModal}
        ceneter={true}
        bsSize={"lg"}
        bodyClassName={"stationselect-modal"}
        content={
          <Row className="p-0">
            <Col md="12" sm="12" xs="12" lg="12">
              <div className="title p-0"> SELECT STATION</div>
              <Row className="p-3">
                <Col md="2" sm="2" xs="2" lg="2">
                  <div
                    className="station-block"
                    onClick={() => cookedFunc("A")}
                  >
                    A
                  </div>
                  <div
                    className="station-block"
                    onClick={() => cookedFunc("1")}
                  >
                    1
                  </div>
                </Col>
                <Col md="2" sm="2" xs="2" lg="2">
                  <div
                    className="station-block"
                    onClick={() => cookedFunc("B")}
                  >
                    B
                  </div>
                  <div
                    className="station-block"
                    onClick={() => cookedFunc("2")}
                  >
                    2
                  </div>
                </Col>
                <Col md="2" sm="2" xs="2" lg="2">
                  <div
                    className="station-block"
                    onClick={() => cookedFunc("C")}
                  >
                    C
                  </div>
                  <div
                    className="station-block"
                    onClick={() => cookedFunc("3")}
                  >
                    3
                  </div>
                </Col>
                <Col md="2" sm="2" xs="2" lg="2">
                  <div
                    className="station-block"
                    onClick={() => cookedFunc("D")}
                  >
                    D
                  </div>
                  <div
                    className="station-block"
                    onClick={() => cookedFunc("4")}
                  >
                    4
                  </div>
                </Col>
                <Col md="2" sm="2" xs="2" lg="2">
                  <div
                    className="station-block"
                    onClick={() => cookedFunc("E")}
                  >
                    E
                  </div>
                  <div
                    className="station-block"
                    onClick={() => cookedFunc("5")}
                  >
                    5
                  </div>
                </Col>
                <Col md="2" sm="2" xs="2" lg="2">
                  <div
                    className="station-block"
                    onClick={() => cookedFunc("F")}
                  >
                    F
                  </div>
                  <div
                    className="station-block"
                    onClick={() => cookedFunc("6")}
                  >
                    6
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>
        }
        closeEvent={() => {
          setStationSelectModal(false);
        }}
      />
    </>
  );
};

export default SelectStation;
