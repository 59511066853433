import StationRepository from "./StationRepository";
import OrderRepository from "./OrderRepository";
import AuthRepository from "./AuthRepository";
import ReceipeRepository from "./ReceipeRepository";
import TableRepository from "./TableRepository";
import ServicesRepository from "./ServicesRepository";
import ParkingStationRepository from "./ParkingStationRepository";

const repositories = {
  station: StationRepository,
  order: OrderRepository,
  auth: AuthRepository,
  recipe: ReceipeRepository,
  table: TableRepository,
  service: ServicesRepository,
  parking: ParkingStationRepository,
};
export const RepositoryFactory = {
  get: (name) => repositories[name],
};
