import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { CLEAR_ORDER_ID, GET_ID } from "../../../store/types";

const OrderListCard = ({ orderId, item }) => {
  const dispatch = useDispatch();
  const IdSaleEntry = useSelector((st) => st?.info?.id?.stationName?._id);

  const StationItemChange = (orderId, item) => {
    dispatch({
      type: GET_ID,
      payload: { Id: orderId, stationName: item },
    });
    dispatch({ type: CLEAR_ORDER_ID });
  };
  return (
    <>
      <div
        className={
          item?._id === IdSaleEntry
            ? "orderlist_div power_selected"
            : "orderlist_div"
        }
        onClick={() => {
          StationItemChange(orderId, item);
        }}
      >
        <div
          className={`power ${
            item.status == "pending"
              ? "powergray"
              : item.status == "cooked"
              ? "powergreen"
              : "power"
          }`}
          style={{ width: item?.station === "insalata" ? "85px" : "55pxF" }}
        >
          {item?.station.toUpperCase()}
        </div>
        <div
          className={
            item?.priority === "urgent"
              ? "ol_header bgitemheader_hotpink"
              : "ol_header"
          }
        >
          {item?.itemName?.toUpperCase()}
          <span className="supper">x{item?.quantity}</span>
        </div>
        <div className="ol_body">
          {item?.subLineItems.map((subitem) => {
            return (
              <>
                {subitem.isSingleOnly &&
                  (subitem.type === "Standard" ||
                    subitem.type === "standard" ||
                    subitem.type === "normal") && (
                    <div className="olb_partition d-flex flex-row">
                      <div className="d-flex flex-row">
                        <div className="olb_text">
                          <span>
                            <img
                              src="/img/icons/MIDARROWRIGHT_BLACK.png"
                              alt="icon"
                            />
                          </span>
                          <ul>
                            <li style={{ fontWeight: "bold" }}>
                              {subitem?.itemName}{" "}
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  )}
              </>
            );
          })}
          {item?.subLineItems.map((subitem, index) => {
            return (
              <>
                {subitem.type === "Extras" ||
                  (subitem.type == "normal" && (
                    <ul>
                      <li key={index}>{subitem?.itemName} </li>
                    </ul>
                  ))}
              </>
            );
          })}

          {/* <span>
              <img src="/img/icons/MIDARROWRIGHT_BLACK.png" alt="icon" />
            </span>
            <h3>LINGUINI </h3> */}

          {/* <div className="olb_partition d-flex flex-row">
            <div className="d-flex flex-row">
              <img
                src="/img/icons/PLUS_BLACK.png"
                alt="plusicon"
                height="15px"
                className="mt-1"
              />
              <div>Onions</div>
            </div>
            <div className="olbp olbp_minus">
              <ul>
                <li className="">
                  <img src="/img/icons/MINUS_BLACK.png" alt="icon" />
                  Extra 1
                </li>
                <li>
                  {" "}
                  <img
                    className="invisible"
                    src="/img/icons/MINUS_BLACK.png"
                    alt="icon"
                  />
                  Extra 2
                </li>
                <li>
                  {" "}
                  <img
                    className="invisible"
                    src="/img/icons/MINUS_BLACK.png"
                    alt="icon"
                  />
                  Extra 3
                </li>
              </ul>
            </div>
          </div> */}
        </div>
      </div>
    </>
  );
};

export default OrderListCard;
