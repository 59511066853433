// /* eslint-disable react-hooks/exhaustive-deps */
import Slider from "react-slick";
import "../../../../src/assets/css//slick.css";
import "../../../../src/assets/css/slick-theme.css";
import { useEffect, useState } from "react";
// import ModalInterface from "../../ModalInterface";
import "../../../../src/assets/css/modalAction.css";
import { useLocation } from "react-router-dom";
// import Draggable from 'react-draggable';
// import { DragDropContainer, DropTarget } from "react-drag-drop-container";
import { Cards } from "./Cards";
// import { CardsContainer } from "./CardsContainer";
import { useDispatch, useSelector } from "react-redux";
import {
  getCourses,
  // getOrders,
  // getSingleOrder,
} from "../../../store/actions/orderAction";
import ChangeStationModal from "./ChangeStationModal/changeStation";
import MangeModal from "./MangeModal/manage";
import StepsModal from "../../Modals/stepsModal";
import { Spinner } from "reactstrap";
// import { CLEAR_ID, CLEAR_ORDER_ID } from "../../../store/types";
import { settings } from "../../../assets/sliderSetting";
import SelectStation from "../../Modals/selectStation";

export const Home = () => {
  const dispatch = useDispatch();
  const dataStore = useSelector((state) => state?.orders?.orders);
  const userStations = useSelector(
    (state) => state?.authUser?.user?.dataStore?.stations
  );
  const sessionOrder = useSelector((state) => state?.orders?.sessionOrders);
  const filterStaion = useSelector((state) => state?.stations?.activeStations);
  const updatedSingleOrder = useSelector((state) => state?.orders?.singleOrder);
  const singleOrderLoader = useSelector(
    (state) => state?.orders?.singleLoading
  );
  const cancelOrderLoader = useSelector(
    (state) => state?.orders?.cancelOrderLoader
  );
  const loading = useSelector((state) => state?.orders?.loading);
  const fetchCoursesLoading = useSelector(
    (state) => state?.orders?.fetchCoursesLoading
  );
  const [orderObj, setOrderObj] = useState({});
  // const errorOrder = useSelector((err) => err);
  const location = useLocation();
  // const [stationItem, SetStationItem] = useState(false);
  const [stationSlectModal, setStationSelectModal] = useState(false);
  let selected_menu = location?.state?.menu;
  const [saleEntrySelected, setSaleEntrySelected] = useState("");
  const [order, setOrder] = useState(false);
  const [singleOrder, setSingleOrder] = useState("");
  // const [removedOrder, setRemovedOrder] = useState("");
  const [allCooked, setAllCooked] = useState(false);
  const [liveData, setLiveData] = useState([]);
  const [listenDataChange, setListenDataChange] = useState(false);
  const orderPriortyChangeLoader = useSelector(
    (state) => state?.orders?.orderPriortyChangeLoader
  );
  const blId = useSelector((state) => state.business.blId);
  if (order === true) {
    setTimeout(() => {
      setOrder(false);
    }, 5000);
  }
  //  <--------------------End of Scoket---------------->
  if (selected_menu === undefined && location.pathname === "/admin/dashboard") {
    selected_menu = "all";
  }
  function fetcFunc() {
    // dispatch(getOrders(blId));
    dispatch(getCourses());
  }
  useEffect(() => {
    if (blId) {
      fetcFunc();
    }
  }, [blId]);

  // Employee Path By Default Tab SETTING
  if (
    location?.state?.menu === undefined &&
    location.pathname === "/employee/dashboard"
  ) {
    selected_menu = userStations?.[0];
  }
  //Filter Process for card
  const getFilteredData = (dataArr) => {
    //this function dynamically removes/updates items from the array without reloading all orders
    //to cater single updated order and re-render individual updated order
    //seperate cases for admin and employee have all been catered, please check block comments for details

    //only show unserved and not cooked orders on LIVE view
    // let data = dataArr.filter((order) => {
    //   return order?.salesEntries?.some((saleEntry)=>{
    //     return !saleEntry?.serve_status
    //   })
    // })
    if (location?.state?.sessionOrders?.length > 0) {
      return sessionOrder;
    }

    let data = [...dataArr]?.filter((order) => {
      return !order?.serve_status;
    });
    if (data?.length === 0) {
      setAllCooked(true);
      return;
    }
    let dataFilterWithCourse = data.map((order) => {
      let salesEntriesWithCourse = order?.salesEntries?.filter((entry) => {
        return (
          filterStaion[entry?.station?.toLowerCase()] && !entry?.serve_status
        );
      });
      let filteredOrder = { ...order, salesEntries: salesEntriesWithCourse };
      return filteredOrder;
    });
    if (
      updatedSingleOrder?._id !== undefined &&
      updatedSingleOrder?._id !== null
      // ||
      // cancelResponse?.orderID !== ""
    ) {
      //for employee we only re-render the selected sale entry
      if (location.pathname.includes("employee")) {
        let orderIndex = data?.findIndex((item) => {
          return item._id === updatedSingleOrder?._id;
        });
        if (orderIndex !== -1) {
          //update item
          let oldIndex = data[orderIndex]?.salesEntries?.findIndex((item) => {
            return item._id === saleEntrySelected;
          });
          let updatedItemIndex = updatedSingleOrder?.salesEntries?.findIndex(
            (item) => {
              return item._id === saleEntrySelected;
            }
          );
          if (
            oldIndex !== -1 &&
            updatedItemIndex !== -1 &&
            oldIndex !== undefined
          ) {
            data[orderIndex].salesEntries[oldIndex] =
              updatedSingleOrder?.salesEntries[updatedItemIndex];
          }
        }
      } else {
        //for admin re-render the entire selected order
        let ind = dataFilterWithCourse?.findIndex((item) => {
          return item?._id === updatedSingleOrder?._id;
        });
        if (ind !== -1) {
          //update order only
          if (
            dataFilterWithCourse[ind]?.salesEntries ===
            updatedSingleOrder?.salesEntries
          ) {
            dataFilterWithCourse[ind] = updatedSingleOrder;
          }
        }
      }
    }
    if (location?.state?.sessionOrders?.length > 0) {
      return sessionOrder;
    } else if (
      selected_menu === "all" &&
      !location.pathname.includes("employee")
    ) {
      const nullOrderRemove = [...dataFilterWithCourse]?.filter((nd) => {
        if (nd?.salesEntries?.length > 0) {
          return nd;
        }
      });
      return [...nullOrderRemove]; //return all orders
    } else if (location.pathname.includes("employee")) {
      var tempOrders = [];
      dataFilterWithCourse.forEach((itx) => {
        if (itx.salesEntries.length > 1) {
          itx.salesEntries.forEach((itx2) => {
            var obj = { ...itx, salesEntries: [itx2] };
            tempOrders.push(obj);
          });
        } else {
          var obj = { ...itx, salesEntries: [itx.salesEntries[0]] };
          tempOrders.push(obj);
        }
      });
      if (selected_menu === "all") {
        let arr = [];
        tempOrders?.map((order) => {
          return order?.salesEntries?.map((item) => {
            userStations.forEach((station) => {
              if (item?.station === station) arr.push(order);
              return;
            });
          });
        });
        return arr;
      } else {
        let arr = tempOrders?.filter((item) => {
          return item?.salesEntries?.some(
            (itx) => itx?.station === selected_menu
          );
        });
        return arr;
      }
    } else {
      let arr = dataFilterWithCourse?.filter((item) => {
        return item?.salesEntries?.some(
          (itx) => itx?.station === selected_menu
        );
      });
      return arr;
    }
  };
  const filterPriorityData = () => {
    if (!dataStore?.length) {
      setLiveData([]);
      return;
    }

    let filteredData = getFilteredData(dataStore)?.sort((a, b) => {
      if (a?.priority === "urgent" && b?.priority !== "urgent") {
        return -1;
      } else if (a?.priority !== "urgent" && b?.priority === "urgent") {
        return 1;
      }
    });
    if (!location?.state?.sessionOrders?.length) {
      filteredData = filteredData?.filter((item) => item.isParent === false);
    }
    let order = [];
    for (let j = 0; j < filteredData?.length; j++) {
      let tempObj = filteredData[j];
      tempObj.salesEntries.sort(function (a, b) {
        return new Date(b?.createdAt) - new Date(a?.createdAt);
      });
      tempObj["updatedAt"] =
        tempObj.salesEntries[tempObj.salesEntries.length - 1].createdAt;
      order.push(tempObj);
    }

    order.sort(function (a, b) {
      // Check if both objects have a station property
      if (a.station && b.station) {
        // Check if one of the objects has a priority property of "urgent"
        if (a.priority === "urgent" && b.priority !== "urgent") {
          return -1; // a has priority of "urgent", sort it first
        } else if (a.priority !== "urgent" && b.priority === "urgent") {
          return 1; // b has priority of "urgent", sort it first
        } else if (a.priority || b.priority) {
          // If both have a priority property but neither is "urgent",
          // sort by priority first, then by latestItem
          if (a.priority === b.priority) {
            return new Date(a.latestItem) - new Date(b.latestItem);
          } else {
            return a.priority - b.priority;
          }
        } else {
          // Sort by latestItem if both have station and neither has priority
          return new Date(a.latestItem) - new Date(b.latestItem);
        }
      } else if (a.station) {
        // Sort by station first if only a has station
        return -1;
      } else if (b.station) {
        // Sort by station first if only b has station
        return 1;
      } else {
        // Sort by latestItem if neither has station
        return new Date(a.latestItem) - new Date(b.latestItem);
      }
    });
    setLiveData(order ?? []);
  };
  useEffect(() => {
    filterPriorityData();
  }, [dataStore, sessionOrder, location?.state?.menu]);
  useEffect(() => {
    if (dataStore) {
      setAllCooked(false);
      filterPriorityData();
    }
  }, [dataStore, sessionOrder, listenDataChange, orderPriortyChangeLoader]);
  if (loading || fetchCoursesLoading || cancelOrderLoader) {
    return (
      <>
        <div className="d-flex align-items-center justify-content-center mt-5">
          <Spinner />
        </div>
      </>
    );
  } else if (liveData?.length === 0 || allCooked) {
    return (
      <>
        <div className="d-flex align-items-center justify-content-center mt-5">
          <span className="text-muted">There are no pending orders</span>
        </div>
      </>
    );
  } else {
    return (
      <>
        <Slider
          {...settings}
          className="mb-5 ml-2"
          style={{ overflow: "hidden" }}
        >
          {[...liveData].map((item, index) => {
            return (
              <Cards
                orderItem={item}
                selected_menu={selected_menu}
                setOrderObj={setOrderObj}
                orders={
                  selected_menu === "all"
                    ? item?.salesEntries
                    : item?.salesEntries?.filter(
                        (itx) => itx.station === selected_menu
                      )
                }
                saleEntrySelected={saleEntrySelected}
                setSaleEntrySelected={setSaleEntrySelected}
                key={index}
                setUpdateOrder={() => {
                  setSingleOrder(item);
                }}
                //only show card loader if current card was selected
                loading={singleOrder?._id === item?._id && singleOrderLoader}
                setStationSelectModal={setStationSelectModal}
                setListenDataChange={setListenDataChange}
                // setDataLoading={setDataLoading}
                // dataLoading={dataLoading}
              />
            );
          })}
        </Slider>

        <MangeModal />
        {/* <ChangeStationModal SetStationItem={SetStationItem} /> */}
        <ChangeStationModal />
        <StepsModal />
        <SelectStation
          stationSlectModal={stationSlectModal}
          setStationSelectModal={setStationSelectModal}
          orderObj={orderObj}
          // setDataLoading={setDataLoading}
          setListenDataChange={setListenDataChange}
        />
      </>
    );
  }
};
