import React, { useState } from "react";
import "./ocard.css";
import { SingleCardCookingFunc } from "../../../utils/cookingFunc";
import { useDispatch } from "react-redux";
// import { changeBumpStatus } from "../../../store/actions/tableSessionsAction";
const OrderFooter = ({
  cooked,
  order,
  dish,
  createdAt,
  cookingStarted,
  sessions,
  setDataLoading,
  setListenDataChange,
  handlePrint,
  handlePrintXML,
  activeStation,
}) => {
  const dispatch = useDispatch();
  const orderCreation = new Date(createdAt);
  const cookingStartedTime = cookingStarted ? new Date(cookingStarted) : false;
  const [current, setCurrent] = useState(new Date());
  function refreshTime() {
    if (dish?.status !== "cooked") {
      setCurrent(new Date());
    }
  }
  setTimeout(refreshTime, 1000);
  // const idStore = useSelector((store) => store?.info?.id);

  const bumpFunc = () => {
    if (window.location.pathname !== "admin/dashboard/single-order") {
      if (activeStation?.displayMode === "Single View") {
        if (dish?.status === "cooking") {
          if (activeStation?.printType === "web") {
            handlePrint();
          } else if (activeStation?.printType === "mobile") {
            handlePrintXML(order);
          }
        }
        SingleCardCookingFunc({
          dispatch,
          order,
          dish,
          sessions,
          setDataLoading,
          setListenDataChange,
        });
      } else if (activeStation?.displayMode === "Divider View") {
        cookForDivideView();
      }
    }
  };
  const cookForDivideView = () => {
    const filterSaleEntry = order?.salesEntries.filter(
      (se) => se.station === activeStation?.title && se.status !== "cooked"
    );
    let dish = filterSaleEntry[0];
    if (dish?.status === "cooking") {
      if (activeStation?.printType === "web") {
        handlePrint();
      } else if (activeStation?.printType === "mobile") {
        handlePrintXML(order);
      }
    }
    SingleCardCookingFunc({
      dispatch,
      order,
      dish,
      sessions,
      setDataLoading,
      setListenDataChange,
    });
  };

  function msConverter(createdAt) {
    let diff = current - createdAt;
    if (diff < 0) {
      //in some cases cooking time out of sync so adjust time
      diff += 50000; //30sec time adjustment
    }
    var msec = Math.abs(diff);
    var hh = Math.floor(msec / 1000 / 60 / 60);
    msec -= hh * 1000 * 60 * 60;
    var mm = Math.floor(msec / 1000 / 60);
    msec -= mm * 1000 * 60;
    var ss = Math.floor(msec / 1000);
    msec -= ss * 1000;
    if (hh < 10) {
      hh = "0" + hh;
    }
    if (mm < 10) {
      mm = "0" + mm;
    }
    if (ss < 10) {
      ss = "0" + ss;
    }
    if (hh === "00") return mm + ":" + ss;
    return hh + ":" + mm + ":" + ss;
  }

  return (
    <div className=" d-flex justify-content-between card-footer">
      <div className="d-flex order-time bg-gray footer-col">
        <span>{msConverter(orderCreation) || "00:00"}</span>
      </div>
      <div
        className={
          dish?.bumped === true && dish?.status === "cooked"
            ? `d-flex dish-sec bg-green`
            : `d-flex dish-sec bg-gray`
        }
        onClick={() => {
          bumpFunc();
        }}
      >
        <img src="/img/icons/BUMP_WHITE.png" height={30} alt="bump" />
      </div>
      <div className={`d-flex over-time ${cooked} footer-col`}>
        <img src="/img/icons/BUMP_WHITE.png" height={20} alt="bump" />
        <span>
          {!cookingStartedTime
            ? // <CountDown />
              "00:00"
            : msConverter(cookingStartedTime)}
        </span>
      </div>
    </div>
  );
};

export default OrderFooter;
