import React from "react";
import ModalInterface from "../../../ModalInterface";
import { useDispatch } from "react-redux";
import { UpdateOrder } from "../../../../store/actions/orderAction";
const CookModal = (props) => {
  // cook, setCook, cardId, handleClose
  const dispatch = useDispatch();
  const cookPlease = () => {
    dispatch(
      UpdateOrder({ id: props?.cardId, finalStatus: { status: "cooked" } })
    );
    props?.setCook(false);
    props?.setUpdateOrder();
    // props?.handleClose();
  };
  return (
    <>
      <ModalInterface
        display={props?.cook}
        //   setDisplay={setDisplay}
        closeEvent={() => {
          props?.setCook(false);
          // props?.handleClose()
        }}
        className={"text-center"}
        size={"md"}
        content={
          <>
            <div className="d-flex justify-content-center">
              <div className="mt-2 ">
                <div
                  className="btn-yellow-modal mt-2"
                  onClick={() => {
                    cookPlease();
                  }}
                >
                  <img
                    src="/img/icons/CHECK_BLACK.png"
                    alt="reject"
                    className="mt-2"
                    height={30}
                  />
                  <span>Confirm</span>
                </div>
              </div>
            </div>
          </>
        }
        title={
          <span>
            <img
              src="/img/icons/BUMP_BLACK.png"
              height={30}
              alt="Cooking complete?"
            />{" "}
            Have you cooked this order?
          </span>
        }
      />
    </>
  );
};

export default CookModal;
