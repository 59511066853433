import React, { useEffect, useState } from "react";
import { Container, Row, Col } from "reactstrap";
import LoginCard from "../components/Login/LoginCard";
import "../assets/css/login.css";
import { useDispatch } from "react-redux";
import { getBusiness } from "../store/actions/bussinessAction";
import BusinessModal from "../components/Modals/businessModal";

const Login = () => {
  const dispatch = useDispatch();
  const [openBidModal, setOpenBidModal] = useState(false);
  const getAllBussiness = () => {
    dispatch(getBusiness());
  };
  useEffect(() => {
    getAllBussiness();
  }, []);

  return (
    <>
      <div className="main-content bg-dark-gray ">
        <div className=" py-3 py-lg-5">
          <Container className="mt-5 pb-5">
            <Row className=" d-flex justify-content-center">
              <Col lg="12" md="12" sm="12" xs="12">
                <div className="logo">
                  <img
                    src="/img/subfont.PNG"
                    alt="logo"
                    height="120px"
                    className="mr-5"
                  />
                </div>
              </Col>
              <LoginCard setOpenBidModal={setOpenBidModal} />
            </Row>
          </Container>
        </div>
        {/* Page content */}
      </div>
      <BusinessModal
        openBidModal={openBidModal}
        setOpenBidModal={setOpenBidModal}
      />
    </>
  );
};

export default Login;
