import Repository from "./Repository";

const SERVICES = "notification";

export default {
  getServiceNotifications(payload) {
    return Repository.get(`${SERVICES}/?${payload}`);
  },
  addServiceNotifications(payload) {
    return Repository.post(`${SERVICES}`, payload);
  },
};
