import {
  FETECH_RECIPES_SUCCESS,
  FETECH_SINGLE_RECIPES_SUCCESS,
  FETECH_SINGLE_RECIPES_FAIL,
  FETECH_RECIPES_FAIL,
  FETECH_SINGLE_RECIPES_REQUEST,
  FETECH_RECIPES_REQUEST,
  RECIPE_RESET_REQUEST,
} from "../types";
import { RecipeDummy } from "../../DummyData/recipeDummy";

import { RepositoryFactory } from "../../repository/RepositoryFactory";

var recipeRepositoroy = RepositoryFactory.get("recipe");
export const getAllRecipe = () => {
  console.log(RecipeDummy, "im in action");
};
export const getRecipeAction = () => async (dispatch) => {
  try {
    dispatch({ type: FETECH_RECIPES_REQUEST });
    const res = await recipeRepositoroy.getRecipe();
    if (res?.data?.success) {
      dispatch({
        type: FETECH_RECIPES_SUCCESS,
        payload: res?.data?.data,
      });
    } else {
      dispatch({
        type: FETECH_RECIPES_FAIL,
        payload: res?.data?.data,
      });
    }
  } catch (error) {
    console.log(error);
    dispatch({
      type: FETECH_RECIPES_FAIL,
      payload: error,
    });
  }
};

export const getSingleRecipe = (payload) => async (dispatch) => {
  try {
    dispatch({ type: FETECH_SINGLE_RECIPES_REQUEST });
    const res = await recipeRepositoroy.getSingleRecipe(payload);
    if (res?.data?.success) {
      dispatch({
        type: FETECH_SINGLE_RECIPES_SUCCESS,
        payload: res?.data?.data,
      });
    } else {
      dispatch({
        type: FETECH_SINGLE_RECIPES_FAIL,
        payload: res?.data?.data,
      });
    }
  } catch (error) {
    dispatch({
      type: FETECH_SINGLE_RECIPES_FAIL,
      payload: error,
    });
  }
};

export const refreshRecipes = () => async (dispatch) => {
  try {
    dispatch({ type: RECIPE_RESET_REQUEST });
    const res = await recipeRepositoroy.getLatestRecipe();
    dispatch({
      type: FETECH_RECIPES_SUCCESS,
      payload: res.data.data,
    });
  } catch (error) {
    console.log(error);
    dispatch({
      type: FETECH_RECIPES_FAIL,
      payload: error,
    });
  }
};
