export var settings = {
  arrows: true,
  speed: 500,
  draggable: true,
  variableWidth: false,
  dots: false,
  infinite: false,
  slidesToShow: 6,
  slidesToScroll: 3,
  initialSlide: 0,
  // focusOnSelect: true,
  nextArrow: <SampleNextArrow />,
  prevArrow: <SamplePrevArrow />,
  responsive: [
    {
      breakpoint: 1920,
      settings: {
        slidesToShow: 6,
        slidesToScroll: 2,
        infinite: false,
        dots: false,
      },
    },
    {
      breakpoint: 1366,
      settings: {
        slidesToShow: 5,
        slidesToScroll: 2,
        infinite: false,
        dots: false,
      },
    },

    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1,
        infinite: false,
        dots: false,
      },
    },
    {
      breakpoint: 992,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1,
        infinite: false,
        dots: false,
      },
    },
    {
      breakpoint: 768,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        infinite: false,
        dots: false,
      },
    },
    {
      breakpoint: 520,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        infinite: false,
        dots: false,
      },
    },
  ],
};

//

export var employeeSettings = {
  arrows: true,
  speed: 500,
  draggable: true,
  variableWidth: false,
  dots: false,
  infinite: false,
  slidesToShow: 5,
  slidesToScroll: 3,
  initialSlide: 0,
  // focusOnSelect: true,
  nextArrow: <EmployeeNextArrow />,
  prevArrow: <EmployeePrevArrow />,
  responsive: [
    {
      breakpoint: 2700,
      settings: {
        slidesToShow: 5,
        slidesToScroll: 3,
        infinite: false,
        dots: false,
      },
    },
    {
      breakpoint: 1366,
      settings: {
        slidesToShow: 4,
        slidesToScroll: 3,
        infinite: false,
        dots: false,
      },
    },

    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 4,
        slidesToScroll: 1,
        infinite: false,
        dots: false,
      },
    },
    {
      breakpoint: 992,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 1,
        infinite: false,
        dots: false,
      },
    },
    {
      breakpoint: 768,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 1,
        infinite: false,
        dots: false,
      },
    },
    {
      breakpoint: 520,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        infinite: false,
        dots: false,
      },
    },
  ],
};
export function EmployeePrevArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{
        ...style,
        backgroundColor: "#038E89",
        margin: "-60px -80px -50px -110px",
        padding: "60px 10px 60px 10px",
      }}
      onClick={onClick}
    />
  );
}
export function EmployeeNextArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{
        ...style,
        backgroundColor: "#038E89",
        margin: "-67px -35px -50px -80px",
        padding: "60px 10px 60px 10px",
      }}
      onClick={onClick}
    />
  );
}
//
export function SamplePrevArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{
        ...style,
        backgroundColor: "#038E89",
        margin: "-50px -80px -50px -65px",
        padding: "60px 10px 60px 10px",
      }}
      onClick={onClick}
    />
  );
}

export function SampleNextArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{
        ...style,
        backgroundColor: "#038E89",
        margin: "-60px 20px -50px -80px",
        padding: "60px 10px 60px 10px",
      }}
      onClick={onClick}
    />
  );
}
