export const updateServeReducer = (payload) => {
  let copyOrders = payload.allOrders;
  let selectedOrderIndex = copyOrders?.findIndex(
    (item) => item?.sessionId === payload?.sessionId
  );
  copyOrders[selectedOrderIndex].salesEntries.forEach((_saleEntry) => {
    if (_saleEntry.bumped == true) {
      _saleEntry.serve_status = payload.serve_status;
      _saleEntry.bumped = false;
    }
  });
  const allcooked = copyOrders[selectedOrderIndex].salesEntries.every(
    (item) => item?.status == "cooked"
  );
  if (allcooked) {
    copyOrders[selectedOrderIndex].status = "cooked";
  }
  return copyOrders;
};

export const sessionServeReducer = (sessions, payload) => {
  let tempSessions = sessions;
  let allOrdersUpdate = updateServeReducer(payload);

  let selectedOrderIndex = allOrdersUpdate?.findIndex(
    (item) => item?.sessionId === payload?.sessionId
  );
  let selectedSession = tempSessions?.findIndex(
    (item) => item?._id === payload?.sessionId
  );
  tempSessions[selectedSession].bump = false;

  const allServeFlag = allOrdersUpdate[selectedOrderIndex].salesEntries.every(
    (item) => item?.serve_status == true
  );
  if (allServeFlag) {
    tempSessions[selectedSession].serve_status = true;
    tempSessions[selectedSession].order_status = "cooked";
  }

  return tempSessions;
};
