export const FETCH_SERVICES_NOTIFICATIONS_REQUEST =
  "FETCH_SERVICES_NOTIFICATIONS_REQUEST";
export const FETCH_SERVICES_NOTIFICATIONS_SUCCESS =
  "FETCH_SERVICES_NOTIFICATIONS_SUCCESS";
export const FETCH_SERVICES_NOTIFICATIONS_FAIL =
  "FETCH_SERVICES_NOTIFICATIONS_FAIL";

export const SAVE_SERVICE_NOTIFICATION_REQUEST =
  "SAVE_SERVICE_NOTIFICATION_REQUEST";
export const SAVE_SERVICE_NOTIFICATION_SUCCESS =
  "SAVE_SERVICE_NOTIFICATION_SUCCESS";
export const SAVE_SERVICE_NOTIFICATION_FAIL = "SAVE_SERVICE_NOTIFICATION_FAIL";
