// /* eslint-disable import/no-anonymous-default-export */
// import { DISH_PRIORITY } from "../store/Type/orderTypes";
import Repository from "./Repository";

const parkingStation = "/parkingstations";

export default {
  parkingStations() {
    return Repository.get(`${parkingStation}`);
  },
  freeParkingStation(payload) {
    return Repository.get(`${parkingStation}/${payload}/free`);
  },
};
