import { Button, Modal, ModalBody, ModalFooter } from "reactstrap";
import React from "react";

const ModalInterface = (props) => {
  return (
    <Modal
      centered
      // fullscreen="sm"

      size={props.bsSize}
      onHide={!props.display}
      toggle={props.closeEvent}
      isOpen={props.display}
      className={props.className}
    >
      {props.title && (
        <div className="headingModal text-dark d-flex align-items-center justify-content-between p-3">
          <h4 className="mb-0">{props.title}</h4>
          <div onClick={props.closeEvent}>
            {" "}
            <img
              src="/img/icons/DENY_BLACK.png"
              alt="reject"
              className="mt-2 "
              height={25}
            />
          </div>
        </div>
      )}
      <ModalBody className={props.bodyClassName}>
        <>{props.content}</>
      </ModalBody>
      {props?.footer && (
        <ModalFooter className="justify-content-center">
          <Button variant="danger" onClick={props.closeEvent}>
            Close
          </Button>
        </ModalFooter>
      )}
    </Modal>
  );
};

export default ModalInterface;
