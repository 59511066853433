export const ORDER_REQUEST = "ORDER_REQUEST";
export const SINGLE_ORDER_REQUEST = "SINGLE_ORDER_REQUEST";
export const FETCH_ORDER = "ORDER_FETCH";
export const FETCH_SINGLE_ORDER = "FETCH_SINGLE_ORDER";
export const ORDER_FAIL = "ORDER_FAIL";
export const ORDER_UPDATE = "ORDER_UPDATE";
export const ORDER_PROCESS_COOKING = "ORDER_PROCESS_COOKING";
export const ORDER_PRIORITY_CHANGE = "ORDER_PRIORITY_CHANGE";
export const ORDER_CANCEL = "ORDER_CANCEL";
export const REMOVE_ITEM = "REMOVE_ITEM";
export const FETCH_SESSION_ORDER = "FETCH_SESSION_ORDER";
export const FETCH_SESSION_ORDER_FAIL = "FETCH_SESSION_ORDER_FAIL";
export const DISH_PRIORITY = "DISH_PRIORITY";
export const BUMP_ORDER = "BUMP_ORDER";
export const SELECT_STATION = "SELECT_STATION";
export const BAR_LOADER = "BAR_LOADER";
//===============COURSES Types================///
export const GET_COURSES = "GET_COURSES";
export const REMOVE_SINGLE_ORDER = "REMOVE_SINGLE_ORDER";

// orders
export const FETCH_ORDERS_REQUEST = "FETCH_ORDERS_REQUEST";
export const FETCH_ORDERS_SUCCESS = "FETCH_ORDERS_SUCCESS";
export const FETCH_ORDERS_FAIL = "FETCH_ORDERS_FAIL";
// single order
export const FETCH_SINGLE_ORDER_REQUEST = "FETCH_SINGLE_ORDER_REQUEST";
export const FETCH_SINGLE_ORDER_SUCCESS = "FETCH_SINGLE_ORDER_SUCCESS";
export const FETCH_SINGLE_ORDER_FAIL = "FETCH_SINGLE_ORDER_FAIL";

// dish periority
export const SET_DISH_PERIORITY_REQUEST = "SET_DISH_PERIORITY_REQUEST";
export const SET_DISH_PERIORITY_SUCCESS = "SET_DISH_PERIORITY_SUCCESS";
export const SET_DISH_PERIORITY_FAIL = "SET_DISH_PERIORITY_FAIL";

//order periority
export const SET_ORDER_PERIORITY_REQUEST = "SET_ORDER_PERIORITY_REQUEST";
export const SET_ORDER_PERIORITY_SUCCESS = "SET_ORDER_PERIORITY_SUCCESS";
export const SET_ORDER_PERIORITY_FAIL = "SET_ORDER_PERIORITY_FAIL";

//dish station
export const SET_DISH_STATION_REQUEST = "SET_DISH_STATION_REQUEST";
export const SET_DISH_STATION_SUCCESS = "SET_DISH_STATION_SUCCESS";
export const SET_DISH_STATION_FAIL = "SET_DISH_STATION_FAIL";

// cancel order
export const SET_ORDER_CANCEL_REQUEST = "SET_ORDER_CANCEL_REQUEST";
export const SET_ORDER_CANCEL_SUCCESS = "SET_ORDER_CANCEL_SUCCESS";
export const SET_ORDER_CANCEL_FAIL = "SET_ORDER_CANCEL_FAIL";

// cancel item
export const SET_ITEM_CANCEL_REQUEST = "SET_ITEM_CANCEL_REQUEST";
export const SET_ITEM_CANCEL_SUCCESS = "SET_ITEM_CANCEL_SUCCESS";
export const SET_ITEM_CANCEL_FAIL = "SET_ITEM_CANCEL_FAIL";

// get courses
export const FETCH_COURSES_REQUEST = "FETCH_COURSES_REQUEST";
export const FETCH_COURSES_SUCCESS = "FETCH_COURSES_SUCCESS";
export const FETCH_COURSES_FAIL = "FETCH_COURSES_FAIL";

// cooking process
export const ORDER_COOKING_PROCESS_REQUEST = "ORDER_COOKING_PROCESS_REQUEST";
export const ORDER_COOKING_PROCESS_SUCCESS = "ORDER_COOKING_PROCESS_SUCCESS";
export const ORDER_COOKING_PROCESS_FAIL = "ORDER_COOKING_PROCESS_FAIL";

// bumping order
export const SET_ORDER_BUMP_REQUEST = "SET_ORDER_BUMP_REQUEST";
export const SET_ORDER_BUMP_SUCCESS = "SET_ORDER_BUMP_SUCCESS";
export const SET_ORDER_BUMP_FAIL = "SET_ORDER_BUMP_FAIL";

//CookingProcessUpdate
export const COOKING_PROCESS_UPDATE_REQUEST = "COOKING_PROCESS_UPDATE_REQUEST";
export const COOKING_PROCESS_UPDATE_SUCCESS = "COOKING_PROCESS_UPDATE_SUCCESS";
export const COOKING_PROCESS_UPDATE_FAIL = "COOKING_PROCESS_UPDATE_FAIL";

//Serve Order
export const SERVE_ORDER_REQUEST = "SERVE_ORDER_REQUEST";
export const SERVE_ORDER_SUCCESS = "SERVE_ORDER_SUCCESS";
export const SERVE_ORDER_FAIL = "SERVE_OPDER_FAIL";
export const UPDATE_PAYMENT_STATUS = "  UPDATE_PAYMENT_STATUS";
