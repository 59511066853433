import {
  ORDER_FAIL,
  FETCH_SESSION_ORDER,
  FETCH_SESSION_ORDER_FAIL,
  BAR_LOADER,
  FETCH_ORDERS_REQUEST,
  FETCH_ORDERS_SUCCESS,
  FETCH_ORDERS_FAIL,
  FETCH_SINGLE_ORDER_REQUEST,
  FETCH_SINGLE_ORDER_SUCCESS,
  FETCH_SINGLE_ORDER_FAIL,
  SET_DISH_PERIORITY_REQUEST,
  SET_DISH_PERIORITY_SUCCESS,
  SET_DISH_PERIORITY_FAIL,
  SET_ORDER_PERIORITY_REQUEST,
  SET_ORDER_PERIORITY_SUCCESS,
  SET_ORDER_PERIORITY_FAIL,
  SET_DISH_STATION_REQUEST,
  SET_DISH_STATION_SUCCESS,
  SET_DISH_STATION_FAIL,
  SET_ORDER_CANCEL_REQUEST,
  SET_ORDER_CANCEL_SUCCESS,
  SET_ORDER_CANCEL_FAIL,
  SET_ITEM_CANCEL_REQUEST,
  SET_ITEM_CANCEL_SUCCESS,
  SET_ITEM_CANCEL_FAIL,
  FETCH_COURSES_REQUEST,
  FETCH_COURSES_SUCCESS,
  FETCH_COURSES_FAIL,
  // ORDER_COOKING_PROCESS_REQUEST,
  // ORDER_COOKING_PROCESS_SUCCESS,
  // ORDER_COOKING_PROCESS_FAIL,
  COOKING_PROCESS_UPDATE_FAIL,
  COOKING_PROCESS_UPDATE_REQUEST,
  COOKING_PROCESS_UPDATE_SUCCESS,
  // SERVE_ORDER_FAIL,
  // SERVE_ORDER_SUCCESS,
} from "../Type/orderTypes";
import { CLEAR_ORDER_ID, CLEAR_ID } from "../../store/types";
import { RepositoryFactory } from "../../repository/RepositoryFactory";
import OrderRepository from "../../repository/OrderRepository";
import { socket } from "../../services/services.sockets";

var orderRepository = RepositoryFactory.get("order");
export const getOrders = (payload) => async (dispatch) => {
  try {
    dispatch({ type: FETCH_ORDERS_REQUEST });
    const res = await orderRepository.getOrders(payload);
    console.log(res);
    // dispatch({
    //   type: FETCH_ORDERS_SUCCESS,
    //   payload: res?.data?.data?.filter((_item) => _item.salesEntries.length),
    // });
  } catch (error) {
    console.log("error", error);
    dispatch({
      type: FETCH_ORDERS_FAIL,
      payload: error,
    });
  }
};

export const getSingleOrder = (payload) => async (dispatch) => {
  try {
    dispatch({ type: FETCH_SINGLE_ORDER_REQUEST });
    const res = await orderRepository.getSingleOrder(payload);
    dispatch({
      type: FETCH_SINGLE_ORDER_SUCCESS,
      payload: res?.data?.data,
    });
  } catch (error) {
    console.log("error", error?.response?.data?.id);
    dispatch({
      type: FETCH_SINGLE_ORDER_FAIL,
      payload: error,
    });
    dispatch({
      type: ORDER_FAIL,
      payload: error,
    });
  }
};

// not in use
export const UpdateOrder = () => async () => {
  // try {
  //   // dispatch({ type: ORDER_REQUEST });
  //   const res = await orderRepository.updateOrder(payload);
  //   dispatch({
  //     type: ORDER_UPDATE,
  //     payload: res?.data,
  //   });
  // } catch (error) {
  //   dispatch({
  //     type: ORDER_FAIL,
  //     payload: error,
  //   });
  // }
};
//Change Station
export const changeOrderStAction = (payload) => async (dispatch) => {
  try {
    dispatch({ type: SET_DISH_STATION_REQUEST });
    const res = await orderRepository.chanageTheSt(payload);
    dispatch({ type: CLEAR_ORDER_ID });
    if (res?.data?.success) {
      dispatch({
        type: SET_DISH_STATION_SUCCESS,
        payload: payload,
      });
    }
  } catch (error) {
    console.log(error);
    dispatch({
      type: SET_DISH_STATION_FAIL,
      payload: error,
    });
    dispatch({ type: CLEAR_ORDER_ID });
  }
};
//Change Order Priority
export const orderPriorityAction = (payload) => async (dispatch) => {
  try {
    dispatch({ type: SET_ORDER_PERIORITY_REQUEST });
    await orderRepository.ordrPriorityRepo(payload);
    dispatch({ type: SET_ORDER_PERIORITY_SUCCESS, payload: payload });
    dispatch({ type: CLEAR_ORDER_ID });
  } catch (error) {
    dispatch({ type: CLEAR_ORDER_ID });
    dispatch({ type: SET_ORDER_PERIORITY_FAIL, payload: error });
  }
};

//DISH PRIORITY
export const dishPrioirtyAction = (payload) => async (dispatch) => {
  try {
    dispatch({ type: SET_DISH_PERIORITY_REQUEST });
    await orderRepository.dishPriority(payload);
    dispatch({ type: SET_DISH_PERIORITY_SUCCESS, payload: payload });
    dispatch({ type: CLEAR_ID });
  } catch (error) {
    dispatch({ type: SET_DISH_PERIORITY_FAIL, payload: error });
    dispatch({ type: CLEAR_ID });
  }
};

//Order Remove
export const orderCancelAction = (payload) => async (dispatch) => {
  try {
    dispatch({ type: SET_ORDER_CANCEL_REQUEST });
    await OrderRepository.cancelOrderRepo(payload.order_id);
    dispatch({ type: SET_ORDER_CANCEL_SUCCESS, payload: payload });
    dispatch({ type: CLEAR_ORDER_ID });
  } catch (error) {
    alert(error?.response?.data?.message);
    dispatch({ type: CLEAR_ORDER_ID });
    dispatch({ type: SET_ORDER_CANCEL_FAIL, payload: error });
  }
};

//Remove Item
export const removeItemAction = (payload) => async (dispatch) => {
  try {
    dispatch({ type: SET_ITEM_CANCEL_REQUEST });
    await OrderRepository.removeItemRepo(payload);
    dispatch({ type: SET_ITEM_CANCEL_SUCCESS, payload: payload });
    dispatch({ type: CLEAR_ID });
    dispatch({ type: CLEAR_ORDER_ID });
  } catch (error) {
    alert(error?.response?.data?.message);
    dispatch({ type: SET_ITEM_CANCEL_FAIL, payload: error });
    dispatch({ type: CLEAR_ID });
    dispatch({ type: CLEAR_ORDER_ID });
  }
};

//Get orders by Session/table
export const getOrdersBySession = (payload) => async (dispatch) => {
  try {
    dispatch({
      type: FETCH_SESSION_ORDER,
      payload: payload,
    });
  } catch (error) {
    dispatch({
      type: FETCH_SESSION_ORDER_FAIL,
      payload: error,
    });
    console.log("error", error);
  }
};

export const getCourses = () => async (dispatch) => {
  try {
    dispatch({ type: FETCH_COURSES_REQUEST });
    let res = await OrderRepository.getCourses();
    dispatch({
      type: FETCH_COURSES_SUCCESS,
      payload: res?.data?.data,
    });
  } catch (err) {
    alert("Something went wrong try again" + err);
    dispatch({ type: FETCH_COURSES_FAIL });
  }
};

export const toggleBarLoader = (payload) => async (dispatch) => {
  try {
    dispatch({ type: BAR_LOADER, payload: payload });
  } catch (err) {
    alert("Failed to set bar loading");
  }
};
//Update cooking Process
export const cookingProcessUpdate = (payload) => async (dispatch) => {
  dispatch({ type: COOKING_PROCESS_UPDATE_REQUEST });
  let obj = {
    dish_status: payload?.dish_status,
    order_id: payload?.order_id,
    sales_entry_id: payload?.sales_entry_id,
    sessionId: payload?.sessionId,
  };
  if (payload?.dish_status === "cooked") {
    obj.bump = payload?.bump;
    obj.bumpStation = payload?.bumpStation;
  }
  try {
    const res = await orderRepository.cookingProcessUpdateRepo(obj);
    dispatch({ type: COOKING_PROCESS_UPDATE_SUCCESS, payload: payload });
    if (res?.data?.message === "Order has cooked completely") {
      socket.emit("recipiesCooked", { sessionId: res.data.sessionId });
    }
  } catch (error) {
    dispatch({ type: COOKING_PROCESS_UPDATE_FAIL });
  }
};
export const getOrdersFromFireBase = (payload) => async (dispatch) => {
  try {
    dispatch({
      type: FETCH_ORDERS_SUCCESS,
      payload: payload?.filter((_item) => _item.salesEntries.length),
    });
  } catch (error) {
    console.log("error", error);
    dispatch({
      type: FETCH_ORDERS_FAIL,
      payload: error,
    });
  }
};
