import Repository from "./Repository";

const RECIPE = "/recipes";

export default {
  getRecipe() {
    return Repository.get(`${RECIPE}`);
  },
  getSingleRecipe(payload) {
    return Repository.get(`${RECIPE + `/` + payload}`);
  },
  getLatestRecipe() {
    return Repository.get(`${RECIPE + `?isCache=false`}`);
  },
};
