import {
  GET_ID,
  CLEAR_ID,
  ORDER_ID,
  CLEAR_ORDER_ID,
  ORDER_DISH_COOK,
} from "../types";
const initialState = {
  id: [],
  oId: [],
  order: [],
};

export const IdReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_ID:
      return {
        ...state,
        id: action.payload,
        oId: "",
      };
    case ORDER_ID:
      return {
        ...state,
        oId: action.payload,
        id: "",
      };
    case CLEAR_ID:
      return {
        ...state,
        id: "",
      };
    case ORDER_DISH_COOK:
      return {
        ...state,
        order: action.payload,
      };
    case CLEAR_ORDER_ID:
      return {
        ...state,
        oId: "",
      };
    default:
      return state;
  }
};
