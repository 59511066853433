import React from "react";

const NumberBtn = ({ number, symbol, actionText, onClick }) => {
  return (
    <>
      {number && (
        <div>
          <button
            name={number}
            type="button"
            className="numberBtn m-2"
            onClick={(e) => onClick(e.target.name)}
          >
            {number}
          </button>
        </div>
      )}
      {symbol && (
        <div className="">
          <button
            className="btnSymbol m-2"
            name={symbol}
            type="button"
            onClick={(e) => onClick(e.target.name)}
          >
            {symbol}
          </button>
        </div>
      )}
      {actionText && <div className=""></div>}
    </>
  );
};

export default NumberBtn;
