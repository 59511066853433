import { Col, Row, Spinner } from "reactstrap";
import ModalInterface from "../ModalInterface";
import "../../../src/assets/css/stationModal.css";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  FreeParkingStations,
  getParkingStations,
} from "../../store/actions/parkingStationAction";

const StationStatusModal = ({ stationStatus, setStationStatus }) => {
  const dispatch = useDispatch();
  const { parkingStations } = useSelector((state) => state?.parking);
  const loading = useSelector((state) => state?.parking?.stationBusyLoading);
  const [stationIndex, setStationIndex] = useState(-1);
  useEffect(() => {
    if (stationStatus === true) {
      dispatch(getParkingStations());
    }
  }, [stationStatus]);
  const freeStationFunc = (station, index) => {
    setStationIndex(index);
    dispatch(FreeParkingStations(station));
  };

  return (
    <>
      <ModalInterface
        display={stationStatus}
        ceneter={true}
        bsSize={"md"}
        content={
          <>
            <Row>
              <Col lg="12" md="12" sm="12" xs="12">
                {parkingStations?.length < 0 ? (
                  <>
                    <div className="text-center text-muted">
                      <p> No Stations Founded ...!</p>
                    </div>
                  </>
                ) : (
                  <div className="d-flex justify-content-center align-items-center p-3">
                    {parkingStations?.map((st, index) => {
                      return (
                        <>
                          {st?.busy ? (
                            <div
                              className="station-btn  d-flex justify-content-center align-items-center m-2"
                              key={index}
                              onClick={() =>
                                freeStationFunc(st?.stationName, index)
                              }
                            >
                              {loading && index === stationIndex ? (
                                <span>
                                  <Spinner />
                                </span>
                              ) : (
                                <span>{st?.stationName}</span>
                              )}
                            </div>
                          ) : (
                            <div
                              className="station-btn station-free d-flex justify-content-center align-items-center m-2"
                              key={index}
                            >
                              <span>{st?.stationName}</span>
                            </div>
                          )}
                        </>
                      );
                    })}
                  </div>
                )}
              </Col>
            </Row>
          </>
        }
        closeEvent={() => {
          setStationStatus(false);
        }}
      />
    </>
  );
};

export default StationStatusModal;
