import { colorChngeReducer } from "./colorChangeReducer";
export function updateOrderBySession(sessionOrder, payload) {
  let [order] = sessionOrder;

  //==== >>>>>>>> FIND DISH INDEX =======
  let selectedDishIndex = order?.salesEntries?.findIndex(
    (item) => item?._id === payload?.sales_entry_id
  );
  let saleEntry = order.salesEntries[selectedDishIndex];

  // let session = payload.sessions[tableSessionIndex];
  //=================== COOOKING FLOW ======================
  if (
    saleEntry.station === "bar" ||
    saleEntry.station === "Bar" ||
    saleEntry.station === "BAR"
  ) {
    //=====================The sale entry is bar &  pending================
    if (saleEntry.status === "pending") {
      return cookingOnlyBarItems(order);
    }
    //=====================The sale entry is bar & cooking================
    else if (saleEntry.status === "cooking") {
      return barBumpItems(order, payload);
    }
  }
  // ==================== NOT BAR ITEMS ==================
  else if (
    saleEntry.station !== "bar" ||
    saleEntry.station !== "Bar" ||
    saleEntry.station !== "BAR"
  ) {
    //=========================Cooking Scenario for not bar Items =======================
    if (payload.dish_status === "cooking") {
      order.salesEntries[selectedDishIndex].status = "cooking";
      order.status = "cooking";
      sessionOrder = colorChngeReducer(order);
      return sessionOrder;
    }
    //=======================Cooked Scenario for not bar Items ==========================
    else if (payload.dish_status === "cooked") {
      return dishCook(order, selectedDishIndex, payload);
    }
  } else {
    return order;
  }
}

const cookingOnlyBarItems = (order) => {
  order.salesEntries.forEach((_saleEntry) => {
    if (
      (_saleEntry.station === "bar" ||
        _saleEntry?.station === "Bar" ||
        _saleEntry?.station === "BAR") &&
      _saleEntry.status !== "cooked"
    ) {
      _saleEntry.status = "cooking"; // cooking
    }
  });

  // session.order_status = "cooking";
  order.status = "cooking";
  return colorChngeReducer(order);
};
//===============Bar item Cooked================
const barBumpItems = (order, payload) => {
  order?.salesEntries.forEach((_saleEntry) => {
    if (
      (_saleEntry.station === "bar" ||
        _saleEntry.station === "Bar" ||
        _saleEntry.station === "BAR") &&
      _saleEntry.status !== "cooked"
    ) {
      _saleEntry.status = "cooked"; // cooking
      _saleEntry.bump = payload?.bump;
      _saleEntry.bumped = payload?.bump;
      _saleEntry.bumpStation = payload?.bumpStation;
      // payload.sessions[tableSessionIndex].bump = true;
    }
  });

  let everyCooked = order?.salesEntries.every(
    (saleEntry) => saleEntry.status === "cooked"
  );
  if (everyCooked) {
    order.status = "cooked";
    // payload.sessions[tableSessionIndex].order_status = "cooked";
  }

  return colorChngeReducer(order);
};

const dishCook = (order, selectedDishIndex, payload) => {
  //dish status cooked
  order.salesEntries[selectedDishIndex].status = payload.dish_status;
  //dish bump
  order.salesEntries[selectedDishIndex].bumped = payload.bump;
  //bump station
  order.salesEntries[selectedDishIndex].bumpStation = payload.bumpStation;
  //Bump true in Table Session
  // payload.sessions[tableSessionIndex].bump = true;

  //if all cooked then order status cooked the order and session
  let everyCooked = order?.salesEntries.every(
    (saleEntry) => saleEntry.status === "cooked"
  );
  if (everyCooked) {
    order.status = "cooked";
    // payload.sessions[tableSessionIndex].order_status = "cooked";
  }
  return colorChngeReducer(order);
};
