import {
  FETECH_STATIONS_FAIL,
  FETCH_STATIONS_REQUEST,
  FETCH_STATIONS_SUCCESS,
} from "../Type/stationTypes";

const initialState = {
  stations: [],
  loading: false,
  activeStations: {},
};

function filterStationFunc(allstations) {
  const filterActiveStations = {};
  allstations?.forEach((station) => {
    if (
      !filterActiveStations[station?.title?.toLowerCase()] &&
      station.enableStation
    ) {
      filterActiveStations[station.title.toLowerCase()] =
        station.title.toLowerCase();
    }
  });
  return filterActiveStations;
}

export const stationReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_STATIONS_REQUEST:
      return {
        ...state,
        loading: true,
        isAuthenticated: false,
      };

    case FETCH_STATIONS_SUCCESS:
      return {
        ...state,
        loading: false,
        stations: action.payload,
        activeStations: filterStationFunc(action.payload),
      };

    case FETECH_STATIONS_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    default:
      return state;
  }
};
