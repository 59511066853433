import {
  SET_BUSINESS,
  FETCH_BUSINESSES_SUCCESS,
  FETCH_BUSINESSES_REQUEST,
  GET_BUSINESSES_FAIL,
} from "../types";

const initialState = {
  allBusiness: [],
  bussinessesLoader: false,
  blId: "",
  getBussinessesError: [],
};
export default function businessReducer(
  state = initialState,
  { type, payload }
) {
  switch (type) {
    case FETCH_BUSINESSES_REQUEST:
      return {
        ...state,
        bussinessesLoader: true,
      };
    case GET_BUSINESSES_FAIL:
      return {
        ...state,
        getBussinessesError: payload,
        bussinessesLoader: false,
      };
    case SET_BUSINESS:
      return {
        ...state,
        blId: payload,
      };
    case FETCH_BUSINESSES_SUCCESS:
      return {
        ...state,
        bussinessesLoader: false,
        allBusiness: payload[0]?.businessLocations,
      };

    default:
      return state;
  }
}
