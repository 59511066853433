import React from "react"; // , { useEffect }
import "../../assets/css/OrderList.css";
import SubHeader from "../../components/Dashboard/OrderListComponents/SubHeader";
import { Spinner } from "reactstrap";
import OrderContainer from "../../components/Dashboard/OrderListComponents/OrderContainer";
// import OLsecondContainer from "../../components/Dashboard/OrderListComponents/OlSecContainer";
import MangeModal from "../../components/Dashboard/Home/MangeModal/manage";
import ChangeStationModal from "../../components/Dashboard/Home/ChangeStationModal/changeStation";
// import { getOrders } from "../../store/actions/orderAction";
import { useSelector } from "react-redux";
// import { CLEAR_ID } from "../../store/types";

const OrderList = () => {
  // const dispatch = useDispatch();

  const loading = useSelector((state) => state?.orders.loading);
  const data = useSelector((state) => state.orders.orders);
  // useEffect(() => {
  //   dispatch(getOrders());

  // }, []);
  // ORDER UPDATE STATUS CHECK OR STATION UPDATE

  // Loading Div
  if (loading) {
    return (
      <>
        <div className="d-flex position-absolute top-50 start-50">
          <Spinner />
        </div>
      </>
    );
  }

  return (
    <>
      <SubHeader />
      <OrderContainer data={data} />
      {/* <HomeView/> */}
      {/* <OLsecondContainer /> */}

      <MangeModal />
      <ChangeStationModal />
    </>
  );
};

export default OrderList;
