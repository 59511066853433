import React from "react";
import { Col, Row } from "reactstrap";
import HomeView from "./Employee/HomeView";

const SingleOrder = () => {
  return (
    <Row>
      <Col md="12" lg="12" sm="12" xs="12">
        <HomeView />
      </Col>
    </Row>
  );
};
export default SingleOrder;
