import {
  FETCH_SERVICES_NOTIFICATIONS_REQUEST,
  FETCH_SERVICES_NOTIFICATIONS_SUCCESS,
  FETCH_SERVICES_NOTIFICATIONS_FAIL,
  SAVE_SERVICE_NOTIFICATION_FAIL,
} from "../Type/ServicesTypes";
const initialState = {
  services: [],
  loading: false,
  fetchServiceError: [],
  saveServiceError: [],
};

export const servicesReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_SERVICES_NOTIFICATIONS_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case FETCH_SERVICES_NOTIFICATIONS_SUCCESS:
      return {
        ...state,
        loading: false,
        services: action.payload,
      };
    case FETCH_SERVICES_NOTIFICATIONS_FAIL:
      return {
        ...state,
        loading: false,
        fetchServiceError: action.payload,
      };
    case SAVE_SERVICE_NOTIFICATION_FAIL:
      return {
        ...state,
        saveServiceError: action.payload,
      };

    default:
      return state;
  }
};
