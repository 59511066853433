export function colorChngeReducer(item) {
  if (item?._id) {
    if (item?.salesEntries?.every((x) => x?.status === "cooked")) {
      item.cookingColor = "bgplaygreen";
    }
    if (item?.salesEntries?.some((x) => x?.status === "cooking")) {
      item.cookingColor = "bgorder-yellow";
    } else {
      item.cookingColor = "bgOrder-light-Gray";
    }
    //Bump Condition
    if (
      item?.salesEntries?.some(
        (x) => x?.bumped === true && x.serve_status === false
      )
    ) {
      item.bumpColor = "bgplaygreen";
    } else {
      item.bumpColor = "bgOrder-light-Gray";
    }
  }
  return item;
}
