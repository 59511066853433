export const FETCH_ALL_TABLE_REQUEST = "FETCH_ALL_TABLE_REQUEST";
export const FETCH_ALL_TABLE_SUCCESS = "FETCH_ALL_TABLE_SUCCESS";
export const FETCH_ALL_TABLE_FAIL = "FETCH_ALL_TABLE_FAIL";

export const SINGLE_TABLE_LOADING = "SINGLE_TABLE_LOADING";
export const CLEAR_SINGLE_TABLE_LOADING = "CLEAR_SINGLE_TABLE_LOADING";

export const CHANGE_TABLE_SESSION_REQUEST = "CHANGE_TABLE_SESSION_REQUEST";
export const CHANGE_TABLE_SESSION_SUCCESS = "CHANGE_TABLE_SESSION_SUCCESS";
export const CHANGE_TABLE_SESSION_FAIL = "CHANGE_TABLE_SESSION_FAIL";

export const FETCH_ALL_SESSIONS_REQUEST = "FETCH_ALL_SESSIONS_REQUEST";
export const FETCH_ALL_SESSIONS_SUCCESS = "FETCH_ALL_SESSIONS_SUCCESS";
export const FETCH_ALL_SESSIONS_FAIL = "FETCH_ALL_SESSIONS_FAIL";

export const DELETE_SESSIONS_REQUEST = "DELETE_SESSIONS_REQUEST";
export const DELETE_SESSIONS_SUCCESS = "DELETE_SESSIONS_SUCCESS";
export const DELETE_SESSIONS_FAIL = "DELETE_SESSIONS_FAIL";

export const PAY_WAITER_REQUEST = "PAY_WAITER_REQUEST";
export const PAY_WAITER_SUCCESS = "PAY_WAITER_SUCCESS";
export const PAY_WAITER_FAIL = "PAY_WAITER_FAIL";

// export const CHANGE_BUMP_TABLE_REQUEST = "CHANGE_BUMP_TABLE_REQUEST";
// export const CHANGE_BUMP_TABLE_STATUS_SUCCESS =
//   "CHANGE_BUMP_TABLE_STATUS_SUCCESS";
// export const CHANGE_BUMP_TABLE_STATUS_FAIL = "CHANGE_BUMP_TABLE_STATUS_FAIL";

export const TABLE_SERVE_UPDATE_REQUEST = "TABLE_SERVE_UPDATE_REQUEST";
export const TABLE_SERVE_UPDATE_SUCCESS = "TABLE_SERVE_UPDATE_SUCCESS";
export const TABLE_SERVE_UPDATE_FAIL = "TABLER_SERVE_UPDATE_FAIL";

export const SELECT_STATION_FAIL = "SELECT_STATION_FAIL";

//Serve Flow Update
export const TABLE_SERVE_REQUEST = "TABLE_SERVE_REQUEST";
export const TABLE_SERVE_SUCCESS = "TABLE_SERVE_SUCCESS";
export const TABLE_SERVE_FAIL = "TABLE_SERVE_FAIL";
