import React from "react";
import { useNavigate } from "react-router-dom";

const TopNavStationBtn = ({
  item,
  index,
  path,
  active,
  setActive,
  setHoverIcon,
  state,
  hoverIcon,
  logoActive,
  logo,
}) => {
  const navigate = useNavigate();

  const navigateToPath = (state) => {
    localStorage.setItem("menu", state);
    setActive(index);
    if (location.pathname.includes("employee")) {
      navigate(`/employee/dashboard${path !== undefined ? "/" + path : ""}`, {
        state: { menu: state },
      });
    } else {
      navigate(`/admin/dashboard${path !== undefined ? "/" + path : ""}`, {
        state: { menu: state },
      });
    }
  };
  return (
    <>
      <div
        className={
          active === index
            ? "topCenter-btns active text-uppercase"
            : "topCenter-btns sliver text-uppercase"
        }
        onMouseEnter={() => logo && setHoverIcon && setHoverIcon(item)}
        onMouseLeave={() => logo && setHoverIcon && setHoverIcon(-1)}
        onClick={() => navigateToPath(state)}
      >
        {logo && (
          <img
            src={active === index || hoverIcon === item ? logoActive : logo}
            alt="Logo"
            className="mt-1 "
            height={40}
            width={40}
          />
        )}
        <span> {item !== "orderList" ? item : ""} </span>
      </div>
    </>
  );
};
export default TopNavStationBtn;
