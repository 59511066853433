import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Col, Row } from "reactstrap";
import ErrorAlert from "../../../../components/Modals/errorAlert";
import KellnerCooked from "./kellnerCooked";

// import KellnerCooking from "./kellnerCooking";

const KellnerReadyView = ({ cookedOrders, activeTab }) => {
  const navigate = useNavigate();
  const [warn, setWarn] = useState(false);
  const [alertMsg, setAlertMsg] = useState("");
  const [selectedSession, setSelectedSession] = useState("");
  const sessionOrders = useSelector((state) => state?.orders?.sessionOrders);
  ///===========Navigate to Order ==============
  useEffect(() => {
    if (selectedSession !== "") {
      if (sessionOrders?.length > 0) {
        navigate("/admin/dashboard", {
          state: { sessionOrders },
        });
        // console.log('redirectt')
      } else {
        alert("No orders currently placed in this session/table");
      }
    }
  }, [sessionOrders]);
  return (
    <>
      <Row>
        <Col md="12" lg="12" sm="12" xs="12">
          <KellnerCooked
            cooking={false}
            cookedOrders={cookedOrders}
            setAlertMsg={setAlertMsg}
            setWarn={setWarn}
            setSelectedSession={setSelectedSession}
            activeTab={activeTab}
          />
        </Col>
      </Row>

      <ErrorAlert alert={warn} setAlert={setWarn} alertMessage={alertMsg} />
    </>
  );
};

export default KellnerReadyView;
