import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Col, Row } from "reactstrap";
import ErrorAlert from "../../../../components/Modals/errorAlert";
import ActiveCards from "./ActiveCards";
import ReadyToServe from "./ReadyToServe";
import KellnerCooking from "../kellnerCooking/kellnerCooking";

const KellnerActiveView = ({ activeSessions }) => {
  const [selectedSession, setSelectedSession] = useState("");
  const sessionOrders = useSelector((state) => state?.orders?.sessionOrders);
  const navigate = useNavigate();
  const [warn, setWarn] = useState(false);
  const [alertMsg, setAlertMsg] = useState("");

  ///===========Navigate to Order ==============
  useEffect(() => {
    if (selectedSession !== "") {
      if (sessionOrders?.length > 0) {
        navigate("/admin/dashboard", {
          state: { sessionOrders, singleOrder: true },
        });
      } else {
        alert("No orders currently placed in this session/table");
      }
    }
  }, [sessionOrders]);
  return (
    <>
      <Row>
        <Col sm="12" xs="12" md="12" lg="12">
          <ReadyToServe
            activeSessions={activeSessions}
            selectedSession={selectedSession}
            setSelectedSession={setSelectedSession}
            setAlertMsg={setAlertMsg}
            setWarn={setWarn}
          />
        </Col>
        <hr className="m-3" />
        <Col md="12" lg="12" sm="12" xs="12">
          <KellnerCooking
            cooking={true}
            setAlertMsg={setAlertMsg}
            setWarn={setWarn}
            setSelectedSession={setSelectedSession}
            cookedOrders={activeSessions}
          />
        </Col>
        <hr className="m-3" />
        <Col sm="12" xs="12" md="12" lg="12">
          <ActiveCards
            activeSessions={activeSessions}
            setSelectedSession={setSelectedSession}
            setAlertMsg={setAlertMsg}
            setWarn={setWarn}
          />
        </Col>
      </Row>
      <ErrorAlert alert={warn} setAlert={setWarn} alertMessage={alertMsg} />
    </>
  );
};

export default KellnerActiveView;
