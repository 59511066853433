// /* eslint-disable import/no-anonymous-default-export */
import Repository from "./Repository";

const TABLE = "/table-sessions/";
const BUSINESS_ID = localStorage.getItem("blId");

export default {
  getAllTables(payload) {
    return Repository.get(`floor-plans/${payload}`);
  },
  getAllTableSessions(payload) {
    return Repository.get(`${TABLE + payload || BUSINESS_ID}`);
  },
  toggleBumpStatus(payload) {
    return Repository.put(`${TABLE}bump/${payload.id}`, payload);
  },
  selectStation(payload) {
    return Repository.put(`${TABLE + "station/" + payload.sessionID}`, payload);
  },
  updataTbSession(payload) {
    return Repository.put(`${TABLE}update-session-table`, {
      id: payload.id,
      table_id: payload.table_id,
    });
  },
  mergeSessions(payload) {
    return Repository.put(`${TABLE + "merge-session/"}`, {
      firstSession: payload.firstSession,
      target_session: payload.target_session,
      table_name: payload.table_name,
    });
  },
  updateServeStation(payload) {
    return Repository.put(`${"order/serve"}`, {
      bumpStatus: payload?.bumpStatus,
      serve_status: payload?.serve_status,
      sessionId: payload?.sessionId,
    });
  },

  removeSession(payload) {
    return Repository.delete(`${TABLE}/${payload}`);
  },
  payWaiterOrder(payload) {
    return Repository.post(`/order/waiter-print`, payload).then((res) => {
      return res;
    });
  },
};
