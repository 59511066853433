import React from "react";
import { useDispatch } from "react-redux";
import { ORDER_ID } from "../../../../store/types";

const LiveCardHeader = ({ item }) => {
  const dispatch = useDispatch();
  const OrderSelectFunc = (orderID) => {
    dispatch({
      type: ORDER_ID,
      payload: {
        Id: orderID,
        priority: item?.priority,
      },
    });
  };
  return (
    <>
      <div className="orderTop">
        <div
          className="ordTp bgorder-darkblue pt-2"
          onClick={() => {
            if (location.pathname.includes("admin")) {
              OrderSelectFunc(item?._id);
            }
          }}
        >
          <div className=" text-align-center">
            <div className="ordTp-icon">
              <img src="/img/icons/BILL_WHITE.png" alt="logo" />
            </div>
            <div className="oT_no">
              {item?.pagerNumber
                ? item.pagerNumber
                : item?._id?.substr(item?._id?.length - 4)}
            </div>
          </div>
        </div>
        <div className="ordTp bgorder-blue pt-2">
          <div className=" text-align-center">
            <div className="ordTp-icon">
              <img src="/img/icons/TABLE_WHITE.png" alt="logo" />
            </div>
            <div className="oT_no">{item?.tableNumber}</div>
          </div>
        </div>
        {item?.order_type === "served" ? (
          <div className="ordTp bgorder-yellow d-flex justify-content-around pt-2">
            <div className=" text-align-center">
              <div>
                <img src="/img/icons/INSTORE_WHITE.png" alt="logo" />
              </div>
              {item?.station ? (
                <div className="oT_no">{item?.station}</div>
              ) : (
                <span className="ordTp-que">
                  <img src="/img/icons/queue.png" alt="logo" />
                </span>
              )}
            </div>
          </div>
        ) : (
          <div className="ordTp bgorder-pink ">
            <span className="ordTp-icon">
              <img src="/img/icons/INSTORE_WHITE.png" alt="logo" />
            </span>
            <span className="oT_no">T</span>
          </div>
        )}
      </div>
    </>
  );
};

export default LiveCardHeader;
