import {
  SESSIONS_FAIL,
  SESSIONS_REQUEST,
  FETCH_TABLES,
  TABLE_MERGE,
} from "../types";

import {
  FETCH_ALL_TABLE_REQUEST,
  FETCH_ALL_TABLE_SUCCESS,
  FETCH_ALL_TABLE_FAIL,
  CHANGE_TABLE_SESSION_REQUEST,
  CHANGE_TABLE_SESSION_SUCCESS,
  CHANGE_TABLE_SESSION_FAIL,
  FETCH_ALL_SESSIONS_REQUEST,
  FETCH_ALL_SESSIONS_SUCCESS,
  FETCH_ALL_SESSIONS_FAIL,
  DELETE_SESSIONS_REQUEST,
  DELETE_SESSIONS_SUCCESS,
  DELETE_SESSIONS_FAIL,
  PAY_WAITER_REQUEST,
  PAY_WAITER_SUCCESS,
  PAY_WAITER_FAIL,
  SELECT_STATION_FAIL,
  // CHANGE_BUMP_TABLE_REQUEST,
  // CHANGE_BUMP_TABLE_STATUS_SUCCESS,
  // CHANGE_BUMP_TABLE_STATUS_FAIL,
  TABLE_SERVE_UPDATE_SUCCESS,
  TABLE_SERVE_UPDATE_FAIL,
  TABLE_SERVE_UPDATE_REQUEST,
  SINGLE_TABLE_LOADING,
  CLEAR_SINGLE_TABLE_LOADING,
  TABLE_SERVE_SUCCESS,
  TABLE_SERVE_FAIL,
  TABLE_SERVE_REQUEST,
} from "../Type/tabelSessionTypes";
import { sessionServeReducer } from "../updateReducer/serveStatusUpdateReducer";
const initialState = {
  allTables: [],
  getallTablesLoader: false,
  getallTablesError: [],
  tableChange: [],
  tableChangeLoader: false,
  tableChangeError: [],
  sessions: [],
  changeBumpStatusLoader: false,
  loading: false,
  selectStationError: [],
  mergeTable: [],
  deleteSessionLoader: false,
  deleteSessionError: [],
  UpdateServeStatusLoading: false,
  updateServeStatus: [],
  payWaiterLoader: false,
  payWaiterError: [],
  errors: {},
  singleLoaderTable: [],
  theServeLoader: false,
};

var allErrors = {};
//After order Cook the Order waits for waiter BumpNserveDishesto bump and serve
const BumpNserveDishes = (id, status, sessions, type, res) => {
  var copyData = [...sessions];
  var SessionIndex = copyData.findIndex((item) => item._id === id);
  copyData[SessionIndex].bumpedDishes.forEach((item) => {
    if (type === "serve") {
      if (res.success) {
        copyData[SessionIndex].bump = false;
        item.serve_status = status;
      } else {
        item.serve_status = status;
      }
    }
    if (type === "bump") {
      item.bumped = status;
    }
  });

  return copyData;
};

export const tableSessionsReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_ALL_TABLE_REQUEST:
      return {
        ...state,
        getallTablesLoader: true,
      };
    case FETCH_ALL_TABLE_SUCCESS:
      return {
        ...state,
        getallTablesLoader: false,
        allTables: action.payload,
      };
    case FETCH_ALL_TABLE_FAIL:
      return {
        ...state,
        getallTablesLoader: false,
        getallTablesError: action.payload,
      };

    case FETCH_ALL_SESSIONS_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case SESSIONS_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case FETCH_ALL_SESSIONS_SUCCESS:
      return {
        ...state,
        loading: false,
        sessions: action.payload,
      };
    case FETCH_ALL_SESSIONS_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case SINGLE_TABLE_LOADING:
      return {
        ...state,
        singleLoaderTable: action.payload,
      };
    case CLEAR_SINGLE_TABLE_LOADING:
      return {
        ...state,
        singleLoaderTable: [],
      };
    // case CHANGE_BUMP_TABLE_REQUEST:
    // return {
    //   ...state,
    //   changeBumpStatusLoader: true,
    // };
    // case CHANGE_BUMP_TABLE_STATUS_SUCCESS:
    //   var BumpDishesData = BumpNserveDishes(
    //     action.payload.id,
    //     action.payload.status,
    //     state.sessions,
    //     "bump"
    //   );
    //   return {
    //     ...state,
    //     changeBumpStatusLoader: false,
    //     sessions: BumpDishesData,
    //   };
    // case CHANGE_BUMP_TABLE_STATUS_FAIL:
    // allErrors[CHANGE_BUMP_TABLE_STATUS_FAIL] = action.payload;
    // return {
    //   ...state,
    //   changeBumpStatusLoader: false,
    //   errors: allErrors,
    // };

    case TABLE_SERVE_UPDATE_REQUEST:
      return {
        ...state,
        // UpdateServeStatusLoading: true,
      };
    case TABLE_SERVE_UPDATE_SUCCESS:
      var ServedTableData = BumpNserveDishes(
        action.payload.sessionId,
        action.payload.serve_status,
        state.sessions,
        "serve",
        action.res
      );
      return {
        ...state,
        UpdateServeStatusLoading: false,
        sessions: ServedTableData,
      };
    case TABLE_SERVE_UPDATE_FAIL:
      allErrors = state.errors;
      allErrors[TABLE_SERVE_UPDATE_FAIL] = action.payload;
      return {
        ...state,
        UpdateServeStatusLoading: false,
        errors: allErrors,
      };

    case SESSIONS_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case FETCH_TABLES:
      return {
        ...state,
        loading: false,
        allTables: action.payload,
      };
    case SELECT_STATION_FAIL:
      return {
        ...state,
        selectStationError: action.payload,
      };

    case CHANGE_TABLE_SESSION_FAIL:
      return {
        ...state,
        tableChangeLoader: false,
        tableChangeError: action.payload,
      };
    case CHANGE_TABLE_SESSION_SUCCESS:
      return {
        ...state,
        tableChangeLoader: false,
        tableChange: action.payload,
      };
    case CHANGE_TABLE_SESSION_REQUEST:
      return {
        ...state,
        tableChangeLoader: false,
      };

    case TABLE_MERGE:
      return {
        ...state,
        loading: false,
        mergeTable: action.payload,
      };

    case DELETE_SESSIONS_REQUEST:
      return {
        ...state,
        deleteSessionLoader: true,
      };
    case DELETE_SESSIONS_SUCCESS:
      var allSessions = [...state.sessions];
      var sessionIndex = allSessions.findIndex(
        (item) => item._id === action.payload
      );
      allSessions.splice(sessionIndex, 1);
      return {
        ...state,
        deleteSessionLoader: false,
        sessions: allSessions,
      };
    case DELETE_SESSIONS_FAIL:
      return {
        ...state,
        deleteSessionLoader: false,
        deleteSessionError: action.payload,
      };
    case PAY_WAITER_REQUEST:
      return {
        ...state,
        payWaiterLoader: true,
      };
    case PAY_WAITER_SUCCESS:
      return {
        ...state,
        payWaiterLoader: false,
      };
    case PAY_WAITER_FAIL:
      return {
        ...state,
        payWaiterLoader: false,
        payWaiterError: action.payload,
      };
    case TABLE_SERVE_REQUEST:
      return {
        ...state,
        theServeLoader: true,
      };
    case TABLE_SERVE_SUCCESS:
      return {
        ...state,
        sessions: sessionServeReducer(state.sessions, action.payload),
        theServeLoader: false,
      };
    case TABLE_SERVE_FAIL:
      return {
        ...state,
        theServeLoader: false,
        errors: action.payload,
      };

    default:
      return state;
  }
};
