import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { serveStatusUpdateSession } from "../../../store/actions/tableSessionsAction";

const BumpCardBtn = ({ color, sessionId }) => {
  const orderStore = useSelector((state) => state?.orders?.orders);

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const readyToBumpNServeFunc = () => {
    if (color === "bgplaygreen") {
      dispatch(
        serveStatusUpdateSession({
          sessionId: sessionId,
          bumpStatus: false,
          serve_status: true,
          allOrders: orderStore,
        })
      ).then(() => {
        location.pathname.includes("employee")
          ? navigate("/employee/dashboard/tables", {
              state: { menu: "all" },
            })
          : navigate("/admin/dashboard/tables", {
              state: { menu: "all" },
            });
      });
    } else {
      alert("There is not dish to Bump.");
    }
  };
  return (
    <div
      className={`plateTag ${color}  d-flex justify-content-center align-items-center`}
      onClick={() => readyToBumpNServeFunc()}
    >
      <img src="/img/icons/BUMP_WHITE.png" height={40} alt="dishLogo" />
    </div>
    // <div
    //   // className={`plateTag ${color}  d-flex justify-content-center align-items-center`}

    //   // className={` ${color}  d-flex justify-content-center align-items-center w-100`}
    //   onClick={() => readyToBumpNServeFunc()}
    // >
    //   <img src="/img/icons/BUMP_WHITE.png" height={50} alt="dishLogo" />
    // </div>
  );
};

export default BumpCardBtn;
