import { RepositoryFactory } from "../../repository/RepositoryFactory";
import {
  FETCH_PARKING_STATIONS_FAIL,
  FETCH_PARKING_STATIONS_LOADING,
  FETCH_PARKING_STATIONS_SUCCESS,
  FREE_PARKING_STATION_FAIL,
  FREE_PARKING_STATION_LOADING,
  FREE_PARKING_STATION_SUCCESS,
} from "../Type/parkingTypes";

var parkingRepostiory = RepositoryFactory.get("parking");
export const getParkingStations = () => async (dispatch) => {
  try {
    dispatch({ type: FETCH_PARKING_STATIONS_LOADING });
    const res = await parkingRepostiory.parkingStations();
    dispatch({
      type: FETCH_PARKING_STATIONS_SUCCESS,
      payload: res.data,
    });
  } catch (error) {
    console.log("error", error);
    dispatch({
      type: FETCH_PARKING_STATIONS_FAIL,
      payload: error,
    });
  }
};
export const FreeParkingStations = (payload) => async (dispatch) => {
  try {
    dispatch({ type: FREE_PARKING_STATION_LOADING });
    const res = await parkingRepostiory.freeParkingStation(payload);
    dispatch({
      type: FREE_PARKING_STATION_SUCCESS,
      payload: res.data,
    });
  } catch (error) {
    console.log("error", error);
    dispatch({
      type: FREE_PARKING_STATION_FAIL,
      payload: error,
    });
  }
};
