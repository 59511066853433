import Repository from "./Repository";

// const ORDER = 'stations/';
const AUTH = "/user-detail/";
const GET_BUSINESSES = "/business/vapiano";

export default {
  getAuth(payload) {
    return Repository.get(`${AUTH + payload}`);
  },
  getBusinessId() {
    return Repository.get(`${GET_BUSINESSES}`);
  },
};
