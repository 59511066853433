import React from "react";
import { useDispatch } from "react-redux";
import { Col, Row } from "reactstrap";
import { getOrdersBySession } from "../../../../store/actions/orderAction";
import KellnerCard from "./KellnerCard";

const ActiveCards = ({
  activeSessions,
  setSelectedSession,
  setWarn,
  setAlertMsg,
}) => {
  const dispatch = useDispatch();
  //This page will only filter
  return (
    <Row>
      {activeSessions
        ?.filter((item) => item?.order_status === "pending" && !item?.bump)
        ?.sort((a, b) => a.table_id - b.table_id)
        ?.map((activeSession, index) => {
          return (
            <Col sm="6" xs="6" md="2" lg="2" key={index}>
              <KellnerCard
                activeSession={activeSession}
                setSelectedSession={() => {
                  setSelectedSession(activeSession);
                  dispatch(getOrdersBySession(activeSession));
                }}
                setWarn={setWarn}
                setAlertMsg={setAlertMsg}
              />
            </Col>
          );
        })}
    </Row>
  );
};
export default ActiveCards;
