export function convertToXml(data) {
  let xml =
    '<epos-print xmlns="http://www.epson-pos.com/schemas/2011/03/epos-print">\n' +
    '<text align="center"/><br/> \n' +
    '<feed unit="30"/>\n' +
    "<text>                                &#10;</text>\n";

  if (data.tableNumber) {
    xml += `<text em="true">${"TABLE"}    -----   ${
      data["tableNumber"]
    }&#10;</text>\n`;
  }
  xml += "<text>------------------------------- &#10;</text>\n";
  data?.salesEntries?.forEach((_salesEntry, index) => {
    if (
      (_salesEntry.station === "Bar" ||
        _salesEntry.station === "bar" ||
        _salesEntry.station === "Bar" ||
        _salesEntry?.station === "Cocktails Station") &&
      _salesEntry.status !== "cooked"
    ) {
      xml += `<text>${index + 1}    ${_salesEntry?.itemName} ${
        _salesEntry?.quantity
      } &#10;</text> \n`;
    }
  });
  xml += "<text>------------------------------- &#10;</text>\n";
  xml += '<feed line="3"/>\n' + '<cut type="feed"/>\n' + "</epos-print>";
  return xml;
}
