import { useEffect, useState } from "react";
import PreprationCard from "../../Dashboard/PreprationCard/Prepration";
// import { GET_ID, CLEAR_ORDER_ID } from "../../../store/types";
import { CLEAR_ID, CLEAR_ORDER_ID } from "../../../store/types";

import { useLocation } from "react-router-dom";
import CookModal from "./CookModal/cookmodal";
// import StartCookModal from "./CookModal/startCook";
import { useDispatch, useSelector } from "react-redux";
import { Spinner } from "reactstrap";
// import { changeBumpStatus } from "../../../store/actions/tableSessionsAction";
// import { socket } from "../../../services/services.sockets";
import LiveCardHeader from "./LiveCard/liveCardHeader";
import LiveBodyCard from "./LiveCard/liveBodyCard";
import LiveDishCourse from "./LiveCard/liveDishCourse";
import LiveCardFooter from "./LiveCard/liveCardFooter";
import BumpCardBtn from "./BumpCardBtn";
// import PagerCardBtn from "./utilsFunc/PagerCardBtn";
// import { getSingleOrder } from "../../../store/actions/orderAction";
export const Cards = (props) => {
  const dispatch = useDispatch();
  const idStore = useSelector((store) => store?.info?.id);
  const ordrIDStore = useSelector((state) => state?.info?.oId?.Id);
  const [dataLoading, setDataLoading] = useState(false);

  const [cardData, setCardData] = useState([]);
  const [cook, setCook] = useState(false);
  const courses = useSelector((state) => state.orders.courses);
  const location = useLocation();
  const dishStationChangeLoader = useSelector(
    (state) => state?.orders?.dishStationChangeLoader
  );
  const dishPeriorityLoading = useSelector(
    (state) => state?.orders?.dishPeriorityLoading
  );
  const removeItemLoading = useSelector(
    (state) => state?.orders?.removeItemLoading
  );
  const orderPriortyChangeLoader = useSelector(
    (state) => state?.orders?.orderPriortyChangeLoader
  );
  // const cookingProcessLoader = useSelector(
  //   (state) => state?.orders?.cookingProcessLoader
  // );
  const cookingUpdateLoader = useSelector(
    (state) => state?.orders?.cookingUpdateLoader
  );

  const theServeLoader = useSelector(
    (state) => state?.tableSession?.theServeLoader
  );
  const selectedDish = useSelector((state) => state?.info.id.Id);
  const selectedOrder = useSelector((state) => state?.info.oId);

  useEffect(() => {
    props?.setListenDataChange(dataLoading);
  }, [dataLoading]);

  useEffect(() => {
    if (!dishPeriorityLoading) {
      setDataLoading(dishPeriorityLoading);
    } else if (props?.orderItem._id === selectedDish) {
      setDataLoading(dishPeriorityLoading);
    }
  }, [dishPeriorityLoading, props?.orderItem._id, selectedDish]);
  useEffect(() => {
    if (!orderPriortyChangeLoader) {
      setDataLoading(orderPriortyChangeLoader);
    } else if (props?.orderItem._id === selectedOrder?.Id) {
      setDataLoading(orderPriortyChangeLoader);
    }
  }, [orderPriortyChangeLoader, props?.orderItem._id, selectedDish?._id]);
  useEffect(() => {
    if (!dishStationChangeLoader) {
      setDataLoading(dishStationChangeLoader);
    } else if (props?.orderItem._id === selectedDish) {
      setDataLoading(dishStationChangeLoader);
    }
  }, [dishStationChangeLoader, props?.orderItem._id, selectedDish]);
  useEffect(() => {
    if (!removeItemLoading) {
      setDataLoading(removeItemLoading);
    } else if (props?.orderItem._id === selectedDish) {
      setDataLoading(removeItemLoading);
    }
  }, [removeItemLoading, props?.orderItem._id, selectedDish]);
  useEffect(() => {
    if (!cookingUpdateLoader) {
      setDataLoading(cookingUpdateLoader);
    }
  }, [cookingUpdateLoader]);

  useEffect(() => {
    if (props && courses.length) {
      let coursesarray = [...courses];
      let swipe = coursesarray[coursesarray.length - 1];
      coursesarray[coursesarray.length - 1] =
        coursesarray[coursesarray.length - 2];
      coursesarray[coursesarray.length - 2] = swipe;
      let result = [];
      coursesarray?.map((item) => {
        result = result.concat(
          props?.orders?.filter((itm) => itm.course === item.title)
        );
      });
      setCardData(result);
    }
  }, [props, courses]);

  let timerToCook = 0;

  const handleClick = (e) => {
    let cardTags = document.getElementsByClassName("order");
    let outsideClicked = true;
    for (let cardTag of Array.from(cardTags)) {
      if (cardTag.contains(e.target)) {
        outsideClicked = false;
        break;
      }
    }
    if (outsideClicked) {
      dispatch({ type: CLEAR_ORDER_ID });
      props?.setSaleEntrySelected("");
    }
  };
  useEffect(() => {
    window.addEventListener("click", handleClick);
    return () => {
      window.removeEventListener("click", handleClick);
    };
  }, []);

  return (
    <>
      <div className="card-tag">
        {location?.state?.singleOrder === true ? (
          <BumpCardBtn
            color={props?.orderItem?.bumpColor}
            sessionId={props?.orderItem?.sessionId}
          />
        ) : null}
        <div
          className={
            ordrIDStore === props?.orderItem._id
              ? "order pb-5 mb-5  order-selected"
              : "order pb-5 mb-5"
          }
        >
          <div
            className={
              ordrIDStore === props?.orderItem._id ? "card-tag-overlay" : "abc"
            }
          ></div>

          <LiveCardHeader item={props?.orderItem} />
          {props?.orderItem?.order_type !== undefined ? (
            <div className="taketime bgorder-darkpink">
              <span>{props?.orderItem?.order_type}</span>
              <span>{props?.orderItem?.sessionName}</span>
            </div>
          ) : (
            ""
          )}
          {props?.orderItem?.priority === "urgent" && (
            <div className="taketime priority ">
              <span>Priority</span>
              <span>
                <img src="/img/icons/ALERT_WHITE.png" alt="logo" />
              </span>
            </div>
          )}
          <div
            className={
              "orderItem_handler pb-3" +
              (props?.orderItem?.priority === "urgent" &&
              props?.orderItem?.order_type !== null
                ? " orderItem_height_takeway"
                : props?.orderItem?.priority === "urgent" ||
                  props?.orderItem?.order_type !== undefined
                ? " orderItem_priority_handler"
                : " orderItem_height_handler")
            }
          >
            {cardData.map((item, index) => {
              timerToCook = timerToCook + item?.preperation_time;
              let singleItemReload =
                location.pathname.includes("admin") ||
                item?._id === props?.saleEntrySelected;

              return (
                <div key={index}>
                  {dataLoading ||
                  (props?.loading && singleItemReload) ||
                  (theServeLoader && props?.orderItem?.sessionId) ? (
                    <div
                      className="d-flex position-absolute"
                      style={{ left: "45%", top: "45%" }}
                    >
                      <Spinner />
                    </div>
                  ) : (
                    <>
                      <div className="orderitem">
                        <div
                          onClick={() => dispatch({ type: CLEAR_ID })}
                          className={
                            idStore?.stationName?._id === item?._id
                              ? "item-overlay"
                              : "abc"
                          }
                        ></div>
                        <LiveDishCourse
                          item={item}
                          cardData={cardData}
                          index={index}
                        />
                        <LiveBodyCard
                          item={item}
                          orderItem={props?.orderItem}
                          setDataLoading={setDataLoading}
                          setUpdateOrder={props?.setUpdateOrder}
                          setOrderObj={props?.setOrderObj}
                          setStationSelectModal={props?.setStationSelectModal}
                        />
                      </div>
                      {props?.selected_menu === "all" && (
                        <PreprationCard
                          cardno={item?._id}
                          steps={item?.steps}
                          ingredients={item?.ingredients}
                        />
                      )}
                    </>
                  )}
                </div>
              );
            })}
          </div>
          <div className="footer-bump d-flex justify-content-between">
            {/* <PagerCardBtn item={props?.orderItem} /> */}
          </div>
          {/* // Footer Section  Timer // */}
          <LiveCardFooter item={props?.orderItem} />
        </div>
      </div>

      {/* // Cook Modal */}

      <CookModal
        cook={cook}
        setCook={setCook}
        cardId={props?.orderItem._id}
        setUpdateOrder={() => {
          props?.setUpdateOrder();
        }}
      />
      {/* <StartCookModal
        startCooking={startCooking}
        setStartCooking={setStartCooking}
        cardId={props?.cardId}
        saleEntry={saleEntry}
        setUpdateOrder={() => {
          props?.setUpdateOrder();
        }}
      /> */}
    </>
  );
};
