//=========================ID Type ==================================
export const GET_ID = "GET_ID";
export const CLEAR_ID = "CLEAR_ID";
export const ORDER_ID = "ORDER_ID";
export const CLEAR_ORDER_ID = "CLEAR_ORDER_ID";
export const ORDER_DISH_COOK = "ORDER_DISH_COOK";

// ===================== MODAL TYPES ==============================
export const STATION_OPEN_MODAL = "STATION_OPEN_MODAL";
export const STATION_CLOSE_MODAL = "STATION_CLOSE_MODAL";

export const MANAGE_ITEM_OPEN_MODAL = "MANAGE_ITEM_OPEN_MODAL";
export const MANAGE_ITEM_CLOSE_MODAL = "MANAGE_ITEM_CLOSE_MODAL";

export const SELECT_STATION_OPEN_MODAL = "SELECT_STATION_OPEN_MODAL";
export const SELECT_STATION_CLOSE_MODAL = "SELECT_STATION_CLOSE_MODAL";

export const STEPS_MODAL_OPEN = "STEPS_MODAL_OPEN";
export const STEP_MODAL_CLOSE = "STEPS_MODAL_CLOSE";

// export const STEPS_MODAL_OPEN = "STEPS_MODAL_OPEN"
// export const STEP_MODAL_CLOSE = "STEPS_MODAL_CLOSE"

export const MERGE_MODAL = "MERGE_MODAL";
export const REMOVE_MERGE_SELECTION = "REMOVE_MERGE_SELECTION";
export const CLEAR_CHANGE_MERGE_MODAL = "CLEAR_CHANGE_MERGE_MODAL";
//===========================================================
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGOUT = "LOGOUT";
export const LOGIN_REQUESTED = "LOGIN_REQUESTED";
export const UPDATE_PROFILE = "UPDATE_PROFILE";
export const LOGIN_FAIL = "LOGIN_FAIL";
export const AUTH_SET_LOADING = "AUTH_SET_LOADING";
export const AUTH_NOTIFICATION = "AUTH_NOTIFICATION";
export const FETCH_BUSINESSES_REQUEST = "FETCH_BUSINESSES_REQUEST";
export const FETCH_BUSINESSES_SUCCESS = "FETCH_BUSINESSES_SUCCESS";
export const GET_BUSINESSES_FAIL = "GET_BUSINESSES_FAIL";

export const SET_BUSINESS = "SET_BUSINESS";
///==============Station Types===================///

export const STATION_REQUEST = "STATION_REQUEST";
export const FETCH_STATIONS = "FETCH_STATION";
export const STATION_FAIL = "STATION_FAIL";
export const CHANGE_THE_STATION = "CHANGE_THE_STATION";

///==============RECIPE Types===================///
export const FETECH_RECIPES_REQUEST = "FETECH_RECIPES_REQUEST";
export const FETECH_RECIPES_SUCCESS = "FETECH_RECIPES_SUCCESS";
export const FETECH_RECIPES_FAIL = "FETECH_RECIPES_FAIL";

export const FETECH_SINGLE_RECIPES_REQUEST = "FETECH_SINGLE_RECIPES_REQUEST";
export const FETECH_SINGLE_RECIPES_SUCCESS = "FETECH_SINGLE_RECIPES_SUCCESS";
export const FETECH_SINGLE_RECIPES_FAIL = "FETECH_SINGLE_RECIPES_FAIL";

export const RECIPE_RESET_REQUEST = "RECIPE_RESET_REQUEST";

///===============TABLE_SESSIONS Types================///
export const FETCH_TABLES = "FETCH_TABLES";
export const FETCH_SESSIONS = "FETCH_SESSIONS";
export const SESSIONS_REQUEST = "SESSIONS_REQUEST";
export const SESSIONS_FAIL = "SESSIONS_FAIL";
export const TABLE_CHANGE = "TABLE_CHANGE";
export const TABLE_MERGE = "TABLE_MERGE";
export const REMOVE_TABLE_SESSION = "REMOVE_TABLE_SESSION";
export const UPDATE_SERVER = "UPDATE_SERVE";
