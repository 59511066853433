import { LOGIN_SUCCESS, LOGOUT, LOGIN_REQUESTED, LOGIN_FAIL } from "../types";

const initialState = {
  user: null,
  uid: null,
  loading: false,
  loginError: [],
};

/*
	Any action related to Profile will go here.
*/

export default function authReducer(state = initialState, { type, payload }) {
  switch (type) {
    case LOGIN_REQUESTED:
      return {
        ...state,
        loading: true,
      };
    case LOGIN_FAIL:
      return {
        ...state,
        loading: false,
        loginError: payload,
      };
    case LOGIN_SUCCESS:
      return {
        ...state,
        loading: false,
        user: payload,
        uid: payload.token,
      };
    case LOGOUT:
      console.log("logout reducer");
      return {
        ...state,
        user: null,
        uid: null,
        loading: false,
        loginError: [],
      };
    default:
      return state;
  }
}
