import {
  STATION_OPEN_MODAL,
  STATION_CLOSE_MODAL,
  STEPS_MODAL_OPEN,
  STEP_MODAL_CLOSE,
  MERGE_MODAL,
  CLEAR_CHANGE_MERGE_MODAL,
  REMOVE_MERGE_SELECTION,
  SELECT_STATION_CLOSE_MODAL,
  SELECT_STATION_OPEN_MODAL,
} from "../types";
const initialState = {
  modal: false,
  stepModal: false,
  recall: true,
  steps: [],
  tableNo: {},
};
export const modalReducer = (state = initialState, action) => {
  switch (action.type) {
    case STATION_OPEN_MODAL:
      return {
        ...state,
        modal: true,
      };
    case STATION_CLOSE_MODAL:
      return {
        ...state,
        modal: false,
      };
    case STEPS_MODAL_OPEN:
      return {
        ...state,
        stepModal: true,
        steps: action.payload,
      };
    case STEP_MODAL_CLOSE:
      return {
        ...state,
        stepModal: false,
        steps: [],
      };
    case SELECT_STATION_CLOSE_MODAL:
      return {
        ...state,
        selectStation: false,
      };
    case SELECT_STATION_OPEN_MODAL:
      return {
        ...state,
        selectStation: true,
      };
    case MERGE_MODAL:
      return {
        ...state,
        tableNo: action.payload,
      };
    case REMOVE_MERGE_SELECTION: {
      return {
        ...state,
        tableNo: {},
      };
    }
    case CLEAR_CHANGE_MERGE_MODAL:
      return {
        ...state,
        tableNo: {},
      };
    default:
      return state;
  }
};
